<!--<button disabled="joseDisabled" mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
  {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
</button> -->
<div class="height-full">
<mat-horizontal-stepper linear  #stepper  class="process__container height-full" (selectionChange)="stepClick($event)">
  <mat-step [stepControl]="eanFormGroup" [optional]="isOptional" [editable]="editFirstStep" [completed] ="eanFormGroup.valid">
    <form [formGroup]="eanFormGroup">
      <ng-template matStepLabel>{{'shared.ean' | translate}}</ng-template>
      <app-validate-ean [ean]="ean" [reference]="reference" [pendingProduct]="pendingProduct"></app-validate-ean>
    </form>
  </mat-step>

  <mat-step [stepControl]="categoryFormGroup" [optional]="isOptional">
    <form [formGroup]="categoryFormGroup">
      <ng-template matStepLabel>{{'secure.products.create_product_unit.component_process.categorization' | translate}}</ng-template>
      <app-search-categorization [detailProduct]="detailProduct" [ean]="ean"></app-search-categorization>
      <div class="buttonProcess">
        <button mat-button matStepperPrevious class="buttonProcessAtras">{{'secure.products.create_product_unit.component_process.back' | translate}}</button>
        <button mat-button matStepperNext [disabled]="!views?.showCat" class="buttonProcessContinuar">{{'secure.products.create_product_unit.component_process.continue' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="basicInfoFormGroup" [completed] ="ean">
    <form [formGroup]="basicInfoFormGroup">
      <ng-template matStepLabel>{{'secure.products.create_product_unit.component_process.basic_information' | translate}}</ng-template>
      <app-basic-information [detailProduct]="detailProduct" [isAdmin]="isAdmin" ></app-basic-information>
      <div class="buttonProcess">
        <button mat-button matStepperPrevious class="buttonProcessAtras">{{'secure.products.create_product_unit.component_process.back' | translate}}</button>
        <button mat-button matStepperNext class="buttonProcessContinuar" [disabled]="!basicInfoFormGroup.valid" (click)="continue_after_basic_info()">{{'secure.products.create_product_unit.component_process.continue' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="especificFormGroup" [completed] ="ean">
    <form [formGroup]="especificFormGroup">
      <ng-template matStepLabel>{{'secure.products.create_product_unit.component_process.specifications' | translate}}</ng-template>
      <app-specification-product [detailProduct]="detailProduct" [ean]="ean"></app-specification-product>
      <div class="buttonProcess">
        <button mat-button matStepperPrevious class="buttonProcessAtras">{{'secure.products.create_product_unit.component_process.back' | translate}}</button>
        <button mat-button matStepperNext class="buttonProcessContinuar">{{'secure.products.create_product_unit.component_process.continue' | translate}}</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="imageFormGroup" [completed] ="ean">
    
    <ng-template matStepLabel>{{'secure.products.create_product_unit.component_process.images' | translate}}</ng-template>
    <p class="textgray">{{'secure.products.create_product_unit.video' | translate}}</p>
    <form [formGroup]="videoFormGroup" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
      <app-assign-video [detailProduct]="detailProduct"></app-assign-video>
    </form>

    <hr class="hrmar"/>

    <form [formGroup]="imageFormGroup">
      <app-assign-images [detailProduct]="detailProduct" [children]="children_created"></app-assign-images>
      <div class="buttonProcess">
          <button mat-button matStepperPrevious class="buttonProcessAtras">{{'secure.products.create_product_unit.component_process.back' | translate}}</button>
          <button mat-button (click)="saveInformationCreation()" class="buttonProcessContinuar" >{{'secure.products.create_product_unit.component_process.save' | translate}}</button>
        </div>
    </form>
    
  </mat-step>
</mat-horizontal-stepper></div>