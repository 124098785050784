<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer [mode]="'over'" [position]="'end'" class="example-sidenav" [disableClose]="true">
    <form class="flexbox flex-wrap justify-center" #createFilterSizes="ngForm" [formGroup]="filterSizes"
      class="seller-filter-sidenav" *ngIf="filterSizes">
      <div class="filter-content">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="filter-header">
          <div>
            <span class="filter-header--title">
              Filtro de listado de tallas
            </span>
          </div>
          <button id="closeMenuApplication" type="button" color="primary" [matTooltip]="'actions.close' | translate"
            matTooltipPosition="below" mat-icon-button mat-button (click)="drawer.toggle()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column">
          <mat-form-field color="primary">
            <input matInput placeholder="Nombre de la talla" formControlName="SizeName" name="sizeName"
              [errorStateMatcher]="matcher">
          </mat-form-field>
        </div>
        <div class="filter-control">
          <button mat-stroked-button color="primary" type="button" (click)="cleanFilter()">
            {{'secure.parametize.support_claims-filter.clear'| translate}}
          </button>
          <button mat-flat-button color="primary" type="submit" (click)="drawer.toggle() && filterApply()"
            [disabled]="!createFilterSizes.form.valid || !createFilterSizes.form.touched">
            {{'secure.parametize.support_claims-filter.filter'| translate}}
          </button>
        </div>
      </div>
    </form>
  </mat-drawer>

  <div class="size-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
    <!--  Titulo del toolbar -->
    <p class="size-title" fxShow [fxShow.xs]="false">
      {{'secure.parametize.title_toolbar' | translate}}
      <small class="sub-title">|
        LISTADO DE TALLAS</small>
    </p>
    <!--  Titulo del toolbar -->
    <p class="size-title" fxShow="false" [fxShow.xs]="true">
      LISTADO DE TALLAS
    </p>
    <div class="styleFlex">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = paginations($event)"></mat-paginator>
      <button type="button" mat-button (click)="drawer.toggle()">
        <mat-icon>filter_list</mat-icon>
        <small class="sub-title-filter">{{'filters.filter' | translate}}</small>
      </button>
    </div>

  </div>

  <div class="margintButtonAdd">
    <button mat-button mat-stroked-button (click)="parametrizeSizes()" id="addSize">
      <strong>AGREGAR TALLA</strong>
    </button>
  </div>


  <div class="table-wrapper">
    <div class="example-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef> Nombre de la talla </th>
          <td mat-cell *matCellDef="let element" style="color: #909090"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="State">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle [checked]=element.status (change)="changeStatusSize(element.label)"></mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="options">
              <button mat-icon-button [matTooltip]="'actions.edit' | translate"
                (click)="parametrizeSizes(element.label)">
                <mat-icon>
                  edit
                </mat-icon>
              </button>
              <button mat-icon-button [matTooltip]="'actions.delete' | translate"
                (click)="deleteSizeDialog(element.label)">
                <mat-icon>
                  delete
                </mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</mat-drawer-container>
<ng-template #dialogContent>
  <form [formGroup]="form" class="full-width brands-modal-margin">
    <div class="brands-input-spinner">
      <mat-form-field class="input-size-width">
        <input matInput placeholder="Nombre de la talla" formControlName="nameSize"
          [errorStateMatcher]="matcher" required>
        <mat-icon matSuffix matTooltip="Añadir tallas" class="material-icons add-keyword-icon vertical-align-top"
          (click)="saveSomeSizes()" *ngIf="!changeNameSize">
          add_circle_outline
        </mat-icon>
        <mat-error *ngIf="form.get('nameSize').hasError('pattern')">
          Nombre de la talla inválido
        </mat-error>
      </mat-form-field>

      <div [ngClass]="keySize.length > 0 ? 'example-chip-list-filters': example-chip-list">
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let key of keySize; let i = index" (removed)="deleteKeySize(i)">
            {{key}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <mat-spinner [diameter]="20" *ngIf="showSpinner"></mat-spinner>
    </div>
    <div class="size-position-option">
      <button type="button" mat-button color="primary" (click)="onNoClick()">{{'actions.cancel' | translate}}</button>
      <button type="submit" mat-raised-button color="primary" class="size-position-option-cancel"
        (click)="confirmation()"
        [disabled]="(form && form.invalid) || (validateKeySize === true)">{{'actions.save' | translate}}</button>
    </div>
  </form>
</ng-template>
