<section id="app-quoting" class="flexbox flex-wrap">
  <app-toolbar-tittle class="app-toolbar-title " [tittleBar]="'secure.offers.historical_admin.components.toolbar.title_offert' | translate" [subtitleBar]="userRol"></app-toolbar-tittle>

  <article class="container-quoting" *ngIf="user?.sellerProfile === 'administrator'">
    <app-quoting-administrator></app-quoting-administrator>
  </article>

  <article class="container-quoting" *ngIf="user?.sellerProfile === 'seller'">
    <app-quoting-seller></app-quoting-seller>
  </article>
</section>