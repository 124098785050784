<app-unit-product-creation-stepper *ngIf="!loading" linear #stepper>
  <cdk-step [label]="'secure.products.unit_product_creation.stepper.ean' | translate" [stepControl]="eanForm">
    <app-validate-ean [productDetails]="productDetails" (eanStepCompleted)="onEanFormChange($event)"
      (eanForm)="eanFormEmit($event)">
    </app-validate-ean>
  </cdk-step>
  <cdk-step [label]="'secure.products.unit_product_creation.stepper.categorization' | translate"
    [stepControl]="productCategorizationForm">
    <app-product-categorization [productDetails]="productDetails"
      (categoryStepCompleted)="onCategoryStepChange($event)">
    </app-product-categorization>
  </cdk-step>
  <cdk-step [label]="'secure.products.unit_product_creation.stepper.basic_information' | translate"
    [stepControl]="basicInformationForm">
    <app-product-basic-information [productDetails]="productDetails" [category]="categoryStep.categoryData"
      (basicInformationStepCompleted)="onBasicInformationStepChange($event)"
      (basicInformationFormEmit)="basicInformationFormEmit($event)" [stepper]="stepper">
    </app-product-basic-information>
  </cdk-step>
  <cdk-step [label]="'secure.products.unit_product_creation.stepper.specifications' | translate"
    [stepControl]="specificationForm">
    <app-specification-product [productDetails]="productDetails" [category]="categoryStep.categoryData"
      (specificationsStepCompleted)="onSpecificationsStepChange($event)"
      (specificationFormEmit)="specificationFormEmit($event)">
    </app-specification-product>
  </cdk-step>
  <cdk-step [label]="'secure.products.unit_product_creation.stepper.images_and_video' | translate">
    <app-product-media [category]="categoryStep.categoryData" [productDetails]="productDetails"
      [isValidProduct]="isValidProductData()" (productCreationCompleted)="saveInformationCreation()"
      (mediaStepCompleted)="onMediaStepChange($event)">
    </app-product-media>
  </cdk-step>
</app-unit-product-creation-stepper>