import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserLoginService, UserParametersService} from '@app/core';
import {Const, RoutesConst} from '@app/shared';
import {CoreState} from '@app/store';
import {SetHeaderTitle} from '@app/store/configuration/actions';
import {Store} from '@ngrx/store';
import {ListProductService} from '@secure/products/list-products/list-products.service';
import {EanServicesService} from '@secure/products/create-product-unit/validate-ean/ean-services.service';
import {ProductDetail} from '@secure/products/models/product-detail.model';


@Component({
  selector: 'app-create-unutary-product',
  templateUrl: './unit-product-creation.component.html',
})
export class UnitProductCreationComponent implements OnInit {
  ean: string;
  reference: string;
  constantes = new Const();

  constructor(
    private store: Store<CoreState>,
    public userService: UserLoginService,
    private router: Router,
    private route: ActivatedRoute,
    public userParams: UserParametersService
  ) {
  }

  ngOnInit() {
    this.userService.isAuthenticated(this);
    this.setProductTitle();
    this.route.params.subscribe(params => {
      if (params['ean']) {
        this.ean = params['ean'];
        this.reference = params['reference'];
      }
    });
  }

  setProductTitle() {
    this.store.dispatch(new SetHeaderTitle({
      title: 'module.Productos',
      subtitle: 'menu.Creación unitaria de producto',
      description: ''
    }));
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.getDataUser();
    } else if (!isLoggedIn) {
      this.router.navigate([`/${RoutesConst.home}`]);
    }
  }

  async getDataUser() {
    this.userParams.getUserData().then(data => {
      if (data.sellerProfile !== this.constantes.seller && this.ean === undefined) {
        this.router.navigate([`/${RoutesConst.home}`]);
      }
    });
  }
 


}
