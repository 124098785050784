import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@app/material.module';
import { ComponentProcessComponent } from './component-process/component-process.component';
import { ValidateEanComponent } from '@app/secure/products/create-product-unit/validate-ean/validate-ean.component';
import { SearchCategorizationComponent } from './categorization/search.component';
import { FormsModule } from '@angular/forms';
import { SearchService } from './categorization/search.component.service';
import { ListCategorizationComponent } from './categorization/list/list.component';
import { TreeComponent } from './categorization/list/tree.component';
import { ProductBasicInfoComponent } from './basic-information/basic-information.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { SpecificationService } from './specifications/specification.component.service';
import { ProcessService } from './component-process/component-process.service';
import { AssignImagesComponent } from './assign-images/assign-images.component';
import { AsignateimageService } from '@app/secure/products/create-product-unit/assign-images/assign-images.component.service';
import { ImageUrlComponent } from '@app/secure/products/create-product-unit/assign-images/image-url/image-url.component';
import { CompoImagesComponent } from '@app/secure/products/create-product-unit/assign-images/compo-images/compo-images.component';
import { SpecificationProductComponent } from './specifications/specification.component';
import { BasicInformationService } from './basic-information/basic-information.component.service';
import { SaveProcessDialogComponent } from './component-process/dialogSave/dialogSave.component';
import { UnitProductCreationRoutingModule } from './unit-product-creation-routing.module';

import { SharedModule } from '@app/shared/shared.module';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AssignVideoComponent } from './assign-video/assign-video.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { YouTubePlayerModule } from '@angular/youtube-player';

// Components
import { UnitProductCreationComponent } from './unit-product-creation/unit-product-creation.component';
import { UnitProductCreationFlowComponent } from './unit-product-creation-flow/unit-product-creation-flow.component';
import { UnitProductCreationStepperComponent } from './unit-product-creation-stepper/unit-product-creation-stepper.component';
import { ProductCategorizationComponent } from './product-categorization/product-categorization.component';
import { ProductCategorySearchComponent } from './product-categorization/product-category-search/product-category-search.component';
import { ProductCategoryBreadcrumbComponent } from './product-categorization/product-category-breadcrumb/product-category-breadcrumb.component';
import { ProductCategoryTreeComponent } from './product-categorization/product-category-tree/product-category-tree.component';
import { ProductCategorizationTreeBuilderService } from './product-categorization/product-categorization-tree-builder.service';
import { ProductBasicInformationComponent } from './product-basic-information/product-basic-information.component';
import { ProductBasicInformationFormComponent } from './product-basic-information/product-basic-information-form/product-basic-information-form.component';
import { ProductSizeSliderComponent } from './product-basic-information/product-size-slider/product-size-slider.component';
import { ProductVariantRegisterFormComponent } from './product-basic-information/product-variant-register-form/product-variant-register-form.component';
import { ProductColorPickerComponent } from './product-basic-information/product-color-picker/product-color-picker.component';
import { ProductMediaComponent } from './product-media/product-media.component';
import { UploadProductVideoUrlComponent } from './product-media/upload-product-video-url/upload-product-video-url.component';
import { UploadProductImageUrlComponent } from './product-media/upload-product-image-url/upload-product-image-url.component';
import { UploadProductImageFileComponent } from './product-media/upload-product-image-file/upload-product-image-file.component';
import { DragAndDropDirective } from './product-media/upload-product-image-file/drag-and-drop.directive';
import { UploadedImagesComponent } from './product-media/uploaded-images/uploaded-images.component';



@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    UnitProductCreationRoutingModule,
    MaterialModule,
    ColorPickerModule,
    AngularEditorModule,
    SharedModule,
    StickyNavModule,
    CdkStepperModule,
    CdkTreeModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatExpansionModule,
    YouTubePlayerModule,
  ],
  declarations: [
    ComponentProcessComponent,
    ValidateEanComponent,
    SearchCategorizationComponent,
    ListCategorizationComponent,
    TreeComponent,
    ProductBasicInfoComponent,
    AssignImagesComponent,
    ImageUrlComponent,
    CompoImagesComponent,
    SpecificationProductComponent,
    SaveProcessDialogComponent,
    AssignVideoComponent,
    // NUEVOS COMPONENTES
    UnitProductCreationComponent,
    UnitProductCreationFlowComponent,
    UnitProductCreationStepperComponent,
    ProductCategorizationComponent,
    ProductCategorySearchComponent,
    ProductCategoryBreadcrumbComponent,
    ProductCategoryTreeComponent,
    ProductBasicInformationComponent,
    ProductBasicInformationFormComponent,
    ProductSizeSliderComponent,
    ProductVariantRegisterFormComponent,
    ProductColorPickerComponent,
    ProductMediaComponent,
    UploadProductVideoUrlComponent,
    UploadProductImageUrlComponent,
    UploadProductImageFileComponent,
    DragAndDropDirective,
    UploadedImagesComponent,
  ],
  exports: [
    ComponentProcessComponent,
    ValidateEanComponent,
    SearchCategorizationComponent,
    ListCategorizationComponent,
    TreeComponent,
    ProductBasicInfoComponent,
    AssignImagesComponent,
    ImageUrlComponent,
    CompoImagesComponent,
    SpecificationProductComponent,
    SaveProcessDialogComponent,
    UnitProductCreationComponent,
    UnitProductCreationFlowComponent,
    UnitProductCreationStepperComponent,
    ProductCategorizationComponent,
    ProductCategorySearchComponent,
    ProductCategoryBreadcrumbComponent,
    ProductCategoryTreeComponent,
    ProductBasicInformationComponent,
    ProductBasicInformationFormComponent,
    ProductSizeSliderComponent,
    ProductVariantRegisterFormComponent,
    ProductColorPickerComponent,
    ProductMediaComponent,
    UploadProductVideoUrlComponent,
    UploadProductImageUrlComponent,
    UploadProductImageFileComponent,
    DragAndDropDirective,
  ],
  entryComponents: [
    SaveProcessDialogComponent
  ],
  providers: [
    SearchService,
    SpecificationService,
    ProcessService,
    AsignateimageService,
    BasicInformationService,
    ProductCategorizationTreeBuilderService
  ]
})
export class UnitProductCreationModule {
}
