<div [ngClass]="data.msjDeleteCategory ? 'margintTopDelete' : ''">
  <div class="container-header">
    <ng-container *ngIf="!!data.icon">
      <div class="container-icon">
        <mat-icon>
          {{data.icon}}
        </mat-icon>
      </div>
    </ng-container>
    <ng-container class="title-container" *ngIf="!!data.title">
      <h1 mat-dialog-title><strong>{{data.title}}</strong></h1>
    </ng-container>
  </div>
  <div [ngClass]="data.messageCenter ? 'text-center' : 'text-justify'" *ngIf="data.msjDeleteCategory">
    <ng-container *ngIf="!!data.message">
      <div class="container__message__delete">
        {{data.message}}
      </div>
    </ng-container>
  </div>
  <mat-dialog-content [ngClass]="data.messageCenter ? 'text-center' : 'text-justify'" *ngIf="!data.msjDeleteCategory">
    <div class="container__message">
      {{data.message}}
    </div>
    <ng-container *ngIf="!!content">
      <ng-template [ngTemplateOutlet]="content">
      </ng-template>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="data.showButtons && !!data.validation" class="container__btn">
    <button id="btn-cancel" type="button" mat-stroked-button color="primary"
      (click)="onNoClick()">{{'actions.cancel' | translate}}</button>
    <button id="btn-confirmation" type="submit" mat-raised-button color="primary" (click)="confirmation()"
      [disabled]="data.validation | async"
      mat-dialog-close>{{!!data.btnConfirmationText ? data.btnConfirmationText: 'actions.accept'| translate}}</button>
  </mat-dialog-actions>
  <mat-dialog-actions [ngClass]="data.msjDeleteCategory ? 'margintTopDeleteButton container__btn' : 'container__btn'"
    *ngIf="data.showButtons && !data.validation">
    <button id="btn-cancel" type="button" mat-stroked-button color="primary"
      (click)="onNoClick()">{{'actions.cancel' | translate}}</button>
    <button id="btn-confirmation" type="submit" mat-raised-button color="primary" (click)="confirmation()"
      [disabled]="data.form && data.form.invalid"
      mat-dialog-close>{{!!data.btnConfirmationText ? data.btnConfirmationText: 'actions.accept' | translate}}</button>
  </mat-dialog-actions>
</div>
