<div class="container" fxLayout="row wrap" fxLayoutAlign="center start">

  <div fxFlex="100" class="light-content">

    <!-- Toolbar de las opciones para la orden -->
    <mat-toolbar class="toolbar-orders-options-table" color="primary">
      <mat-toolbar-row class="toolbar-options">

        <div class="normal-theme toolbar-options-table">
          <div fxFlex.gt-lg="60" fxFlex.gt-xs="auto">
            <h3 fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
              class="sc-title title-toolbar">{{'secure.offers.bulk_upload.bulk_upload.title_toolbar' | translate}}
            </h3>
          </div>

          <!-- Paginación de la tabla -->
          <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" fxFlex="65"
            fXLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            
            <!-- Input para subir archivo Excel -->

            <button color="primary" class="sc-default-button" id="download-file-button" (click)="requestMail('Technology')"
              mat-raised-button>
              <mat-icon class="mat-18 text_white">file_download</mat-icon>
              {{'secure.offers.bulk_upload.bulk_upload.download_format_offer' | translate}} {{'secure.offers.bulk_upload.bulk_upload.download_format_estandar' | translate}}
            </button>

            <button color="primary" class="sc-default-button" id="download-file-button" (click)="requestMail('Clothing')"
              mat-raised-button>
              <mat-icon class="mat-18 text_white">file_download</mat-icon>
              {{'secure.offers.bulk_upload.bulk_upload.download_format_offer' | translate}} - {{'secure.offers.bulk_upload.bulk_upload.download_format_variant' | translate}}
            </button>
            
            
            <!-- Input para subir archivo Excel -->
            <input placeholder="file upload" hidden name="fileUploadOption" type="file" (change)="onFileChange($event)"
              multiple="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              #fileUploadOption>
            <button color="primary" class="sc-default-button" id="load-file-button" (click)="fileUploadOption.click()"
              mat-button>
              <mat-icon class="mat-18 ">file_upload</mat-icon>
              {{'secure.offers.bulk_upload.bulk_upload.upload_format' | translate}}
            </button>

          </div>

          <div fxShow.xs="true" fxShow.sm="true" fxShow.md="false" fxShow.lg="false" fxShow.xl="false"
            class="center-product-list" fxFlex="130px">
            <button id="dropdown-options-load-guide" class="button-toolbar" mat-button
              [matMenuTriggerFor]="toolbarOptions">
              <span>{{'actions.options' | translate}}</span>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu dense #toolbarOptions="matMenu">
              <button id="upload-file-guide-button" (click)="fileUploadOption.click()" mat-menu-item>
                <span>{{'secure.products.bulk_upload.upload_file' | translate}}</span>
              </button>
              <a download id="download-file-guide-button" href="assets/format_guide/Format_Guide.xlsx" mat-menu-item>
                {{'secure.products.bulk_upload.download_format' | translate}}</a>
              <button *ngIf="fileName != ''" (click)="resetVariableUploadFile()" id="download-order-button"
                mat-menu-item>
                <span>{{'secure.products.bulk_upload.clear_current_data' | translate}}</span>
              </button>
              <button id="send-guide-button" (click)="sendJsonInformation()"
                [disabled]="countErrors != 0 || countRowUpload == 0" color="accent"
                mat-menu-item>{{'secure.products.bulk_upload.send_data' | translate}}
              </button>
            </mat-menu>
          </div>
          <!-- Paginación de la tabla -->

        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- Toolbar de las opciones para la orden -->
  </div>


  <div *ngIf="!orderListLength && countErrors > 0" class="content-expansion-orders" fxFlex.xs="93%" fxFlex.sm="95%"
    fxFlex.md="30%" fxFlex.lg="30" fxFlex.xl="40">

    <!-- Componente para visualizar la lista de errores -->
    <app-table-errors [fileName]="fileName" [countRowUpload]="countRowUpload" [countErrors]="countErrors"
      [listLog]="listLog"></app-table-errors>
  </div>

  <!-- Tabla de órdenes -->
  <div *ngIf="!orderListLength && countErrors > 0" fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="60" fxFlex.lg="60"
    fxFlex.xl="50" class="content-table-orders">
    <!-- Componente para visualizar la lista de resultados obtenidos -->
    <app-table-load  [countErrors]="countErrors" [countRowUpload]="countRowUpload"></app-table-load>

  </div>

  <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="70" fxFlex.lg="60" fxFlex.xl="60" class="content-table-orders">
    <div [@detailExpand] class="orders-no-data" *ngIf="countRowUpload == 0">
      <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
        class="content-icon-no-data">
        <mat-icon id="icon-cloud-upload-file" (click)="fileUploadOption.click()" class="icon-not-found" color="primary">
          backup
        </mat-icon>
      </div>
      <div class="content-information-no-data">
        <p>{{'secure.products.bulk_upload.text_step_import' | translate}}
          <a download id="download-file-guide-button" (click)="requestMail()"
            class="link-text">{{'secure.products.bulk_upload.download_the_format' | translate}}
          </a>, {{'secure.offers.bulk_upload.bulk_upload.text_step_import_to_offer' | translate}}
        </p>
        <p>
          <span class="link-text" id="upload-file-guide-button"
            (click)="fileUploadOption.click()">{{'secure.products.bulk_upload.text_select_import' | translate}}</span>
          {{'secure.products.bulk_upload.text_select_import_type' | translate}}</p>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogContent>
  <div class="bulk-load-list-errors">
    <div>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item *ngFor="let item of ListError">
          <mat-icon mat-list-icon class="icon-color-error">warning</mat-icon>
          <h4 matLine>{{item.NAME === null ? ('EAN : ' + item.EAN) : ('Nombre del producto: ' + item.NAME)}}</h4>
          <p matLine>{{'secure.offers.bulk_upload.bulk_upload.list_error' | translate}} {{item.EAN}}
            {{'secure.offers.bulk_upload.bulk_upload.list_error_2' | translate}} </p>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>
</ng-template>
