<mat-sidenav-container class="example-container">
  <!-- Sidebar Principal -->
  <mat-sidenav #sidenav mode="over" opened="{{stateSideNav}}">
    <app-sidebar [user]="user" [sidenav]="sidenav"></app-sidebar>
  </mat-sidenav>

  <!-- Sidebar Busqueda de órdenes -->
  <mat-sidenav #sidenavSearchOrder mode="over" position="end" opened="{{stateSideNavOrder}}">
    <app-search-order-menu [paginator]="paginator" [informationToForm]="informationToForm" [state]="state" [idSeller]="idSeller" [typeProfiel]="typeProfiel" [sidenavSearchOrder]="sidenavSearchOrder"></app-search-order-menu>
  </mat-sidenav>

  <!-- Contenido principal -->
  <mat-sidenav-content>
    <!-- Componente Header de la página -->
    <app-header [sidenav]="sidenav" [user]="user"></app-header>
    <app-toolbar-link [user]="user" class="light-content" fxShow.xs="false" fxShow.sm="false" fxShow.gt-lg="true" fxShow="true"></app-toolbar-link>
    <!-- Render módulos-->
    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
