<!-- Movile option -->
<div fxShow="false" fxShow.xs="true" fxShow.sm="true" fxShow.gt-md="false" class="w-100">
  <mat-toolbar class="tree-toolbar" style="position:fixed; z-index: 1;">
    <div class="normal-theme toolbar-options-table">
      <!-- Titulo del toolbar -->
      <p  fxShow.sm="false" fxShow.xs="false" class="tree-title">{{'secure.parametize.category.categories.subtitle' | translate}}</p>
    </div>
  </mat-toolbar>

  <!-- Toolbar para el buscador -->
  <mat-toolbar class="tree-toolbar">
    <div class="normal-theme d-c toolbar-options-table pl-0">
      <!-- Componente para realizar la busqueda de una tienda -->
      <app-search-store id="contet-searc-store" [searchStoreInput]="searchStore" fxFlex="100%"></app-search-store>

      <button *ngIf="informationForTreeIsLoad == true" color="primary" class="button-more-ver btf ml-20" mat-icon-button [matMenuTriggerFor]="TreeToolbar">
        <mat-icon matPrefix>more_vert</mat-icon>
      </button>
      <mat-menu #TreeToolbar="matMenu">
        <button (click)="modifyCommission()" id="btn-save-tree" mat-menu-item fxFlex="170px" class="buttn-toolbar-options">
          <span>{{'actions.save' | translate}}</span>
        </button>
        <button id="btn-file-tree" mat-menu-item fxFlex="170px" class="buttn-toolbar-options" (click)="saveTreeToExcel()">
          <span>{{'actions.download' | translate}}</span>
        </button>
        <button (click)="expandAllNodes(true)" id="btn-expand-tree" mat-menu-item fxFlex="170px" class="buttn-toolbar-options">
          <span>{{'secure.parametize.category.categories.btn_expand' | translate}}</span>
        </button>
        <button (click)="expandAllNodes(false)" id="btn-collapse-tree" mat-menu-item fxFlex="170px" class="buttn-toolbar-options">
          <span>{{'secure.parametize.category.categories.btn_contract' | translate}}</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</div>


<!-- Web Desing -->
<div fxShow.xs="false" fxShow.sm="false" fxShow.gt-md="true" fxLayout.xs="row" fxFlex="100%">
  <mat-toolbar class="tree-toolbar" style="position:fixed; z-index: 1;">
    <div class="normal-theme toolbar-options-table">
      <!-- Titulo del toolbar -->
      <p class="tree-title">{{'secure.parametize.category.categories.categories' | translate}}
        <small class="sub-title">| {{'secure.parametize.category.categories.subtitle' | translate}}</small>
      </p>

      <!-- Componente para realizar la busqueda de una tienda -->
      <app-search-store id="contet-searc-store" class="search-content" fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="50%"
        fxFlex.lg="537px" fxFlex.xl="537px"></app-search-store>

      <!-- Botones del toolbar -->
      <div *ngIf="informationForTreeIsLoad == true" class="options-toolbar" fxFlex="400px" fxFlex.xs="400px" fxFlex.sm="400px"
        fxFlex.md="400px" fxFlex.lg="400px" fxFlex.xl="400px" fxLayoutGap="20px">
        <button id="btn-save-tree" class="sc-default-button" (click)="modifyCommission()" mat-button>{{'actions.save' | translate}}</button>
        <button id="btn-expand-tree" class="sc-default-button" (click)="expandAllNodes(true)" mat-button>{{'secure.parametize.category.categories.btn_expand' | translate}}</button>
        <button id="btn-collapse-tree" class="sc-default-button" (click)="expandAllNodes(false)" mat-button>{{'secure.parametize.category.categories.btn_contract' | translate}}</button>
        <button id="btn-file-tree" (click)="saveTreeToExcel()" mat-icon-button>
          <mat-icon>archive</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
</div>
