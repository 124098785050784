<div class="container" fxLayout="row wrap" fxLayoutAlign="center start">

  <div fxFlex="100" class="light-content">
    <!-- Barra de opciones -->
    <mat-toolbar class="toolbar-orders-options-table" color="primary">
      <mat-toolbar-row class="toolbar-options">

        <div class="normal-theme toolbar-options-table">
          <!-- Título del componente -->
          <div fxFlex.gt-lg="60" fxFlex.gt-xs="auto">
            <h3 fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" class="sc-title title-toolbar"
              > {{'secure.products.bulk_load_product_moderation.product_validation' | translate}}</h3>
          </div>

          <!-- Botones de descargar e importar archivo -->
          <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" fxFlex="60"
            fXLayout="row wrap" fxLayoutAlign="center center">

            <!-- Botón para descargar el formato -->
            <button class="sc-default-button" id="download-file-button" mat-button (click)="downloadFile()">
              <mat-icon class="mat-18">file_download</mat-icon>
              {{'secure.products.bulk_load_product_moderation.download_file' | translate}}
            </button>

            <!-- Input para subir archivo de Excel -->
            <input placeholder="file upload" hidden name="fileUploadInput" type="file" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              #fileUploadInput (change)="fileUploadInputChanged($event)">

            <!-- Botón para importar el archivo -->
            <button color="primary" class="sc-default-button" [ngClass]="{'block-link' : disabledLoad}" id="load-file-button" (click)="fileUploadInput.click()"
              mat-button>
              <mat-icon class="mat-18 ">file_upload</mat-icon>
              {{'secure.products.bulk_load_product_moderation.download_file_import' | translate}}
            </button>
          </div>

          <!-- Menú de opciones para pantallas reducidas -->
          <div fxShow.xs="true" fxShow="false" class="center-product-list" fxFlex="130px">
            <button id="dropdown-options-load-guide" class="button-toolbar" mat-button [matMenuTriggerFor]="toolbarOptions">
              <span>Opciones</span>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu dense #toolbarOptions="matMenu">
              <!-- Opciones del menú -->
              <button id="upload-file-guide-button" (click)="fileUploadInput.click()" mat-menu-item>
                <span>Cargar archivo</span>
              </button>
              <button id="download-file-guide-button" mat-menu-item (click)="downloadFile()">
                Descargar archivo
              </button>
              <!-- <button *ngIf="fileName != ''" (click)="resetVariableUploadFile()" id="download-order-button"
                mat-menu-item>
                <span>Limpiar datos actuales</span>
              </button> -->
              <!-- <button id="send-guide-button" color="accent" mat-menu-item>Enviar registro</button> -->
            </mat-menu>
          </div>

        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <!-- Contenido principal -->
  <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="70" fxFlex.lg="60" fxFlex.xl="60" class="content-table-orders" *ngIf="!showErrorView && showPrincipalContain">
    <div class="orders-no-data">
      <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" class="content-icon-no-data">
        <mat-icon id="icon-cloud-upload-file" (click)="fileUploadInput.click()" class="icon-not-found" color="primary">backup
        </mat-icon>
      </div>
      <div class="content-information-no-data">
        <!-- Template para contenido principal -->
        <ng-container *ngIf="config.mainContentTpl" [ngTemplateOutlet]="config.mainContentTpl"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="showErrorView && dataToSend" class="width-100">
    <app-show-errors [dataWithErrors]="dataToSend" [numberErrors]="counterErrors" [errorsPosition]="errorsPosition" [fileName]="fileName"></app-show-errors>
  </div>

</div>