
  <div class="tree-categories">
    <div *ngFor="let node of treeData" [ngClass]="{'tree-item-selected' : !node.Son.length}">
      <div class="tree-item-categories" [ngClass]="{'tree-selected': node.Id === idSelected}" fxLayout="row" fxLayoutAlign="start center" (click)="selectCategory(node) && !node.Son.length">
        <i [ngStyle]="{'margin-left':getMargin()}" class="material-icons" (click)="openSonClick(node)" *ngIf="!node.Show && node.Son.length">
          add
        </i>
        <i [ngStyle]="{'margin-left':getMargin()}" class="material-icons" (click)="openSonClick(node)" *ngIf="node.Show && node.Son.length">
          remove
        </i>
        <span class="tree-item" [innerHTML]="getName(node)" [ngClass]="{'tree-item-father' : !node.IdParent}"  [ngStyle]="{'margin-left':getMargin(node)}"></span>
      </div>
      <app-tree [treeData]="node.Son" [idSelected]="idSelected"  [open]="openSon" [margin]="margin" *ngIf="node.Show"></app-tree>
    </div>
  </div>
  