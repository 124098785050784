<!-- Web Desing -->
<div>
    <mat-toolbar class="tree-toolbar">
        <div class="normal-theme toolbar-options-table flexbox justify-start align-center">
            <!-- Titulo del toolbar -->
            <p class="tree-title">{{'secure.seller.manage.lb_title_toolbar' | translate}}
                <small class="sub-title">| {{'secure.seller.manage.lb_subtitle_toolbar' | translate}}</small>
            </p>

            <!-- Componente para realizar la busqueda de una tienda -->
            <app-search-seller [searchSellerInput]="name" id="contet-searc-store" class="search-content"></app-search-seller>
        </div>
    </mat-toolbar>
</div>