<div>
  <mat-toolbar class="tree-toolbar">
    <div class="normal-theme toolbar-options-table flexbox justify-start align-center" fxLayout.xs="column">
      <!-- Titulo del toolbar -->
      <p class="tree-title">
        {{'secure.seller.contracts.lb_title_toolbar' | translate}}
        <small class="sub-title">| {{'menu.Administrar Acuerdos' | translate}}</small>
      </p>
    </div>
  </mat-toolbar>
</div>

<div id="addButton" fxLayout="row" fxLayoutAlign="space-between center">
  <div align="left" class="styleFlex">
    <h4 class="subtitleAgreement" *ngIf="manageAgreementsSeller">Acuerdo predeterminado:  </h4> <h4 class="subtitleAgreement2" *ngIf="titleAgreement">{{titleAgreement}}</h4>
  </div>
  <div class="styleFlex">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = paginations($event)"></mat-paginator>
    <button mat-button mat-stroked-button color="primary" class="borderButtonUpload" (click)="openModalBulkLoadAgreement()" id="openDialogAgreement">
      <strong>{{'menu.Cargar Acuerdos' | translate}}</strong>
    </button>
  </div>
 
</div>

<div class="list-container">
  <div class="agreement">
    <div class="agreement-title" fxLayout="row" fxLayoutAlign="space-between center">
      <p>{{'shared.name' | translate}}</p>
      <p id="marginTitle1">Acuerdo predeterminado</p>
      <p id="marginTitle2">{{'shared.actions' | translate}}</p>
    </div>
    <div *ngFor="let agreement of manageAgreementsSeller" fxLayout="row" fxLayoutAlign="space-between center"
      class="list-agreement">
      <div fxLayoutAlign="start center" fxLayout="row" class="width-Name">
        <mat-icon class="material-icons" class="file-agreement">
          insert_drive_file
        </mat-icon>
        <p>{{agreement.Name}} - {{agreement.Date | date}}</p>
      </div>
      <div class="width-agreement-default">
        <mat-slide-toggle (change)="activeContract($event, agreement)" [checked]=agreement.Default
        ></mat-slide-toggle>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-around center" class="width-delete">
        <div fxLayout="column" fxLayoutAlign="space-around center">
          <button mat-icon-button (click)="openModalDeleteAgreement(agreement)">
            <mat-icon class="material-icons" id="sizeIcons">
              delete
            </mat-icon>
          </button>
          <span id="sizeIcons2">Eliminar</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around center">
          <button mat-icon-button (click)="redirectToDetailAgreement(agreement)">
            <mat-icon class="material-icons"  id="sizeIcons">
              remove_red_eye
            </mat-icon>
          </button>
          <span id="sizeIcons2">Ver detalle</span>
        </div>
      </div>
      
      
    </div>
  </div>
</div>
