<div class="comboImage" *ngIf="createImage">
  <form #createImageProductEan="ngForm" [formGroup]="createImage">
    <mat-card class="createImagenPU">
      <img mat-card-image src={{imgUrl}}>
    </mat-card>

    <mat-form-field class="example-full-width">
      <input matInput #inputImage_ formControlName="inputImage"
        [placeholder]="'secure.products.create_product_unit.assing_images_url.image' | translate"
        (keyup)="sendChange(inputImage_.value)" (change)="pushURLImage(inputImage_.value)">
      <mat-error *ngIf="createImage.get('inputImage').hasError('pattern')">
        {{'secure.products.create_product_unit.assing_images_url.format_invalidate' | translate}}</mat-error>
      <mat-error
        *ngIf="createImage.controls.inputImage.errors &&  createImage.controls.inputImage.errors.validFormatImage">
        {{'secure.products.create_product_unit.assing_images_url.imagen_invalidate' | translate}}</mat-error>
    </mat-form-field>
  </form>
</div>
