import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { SpecificationService } from './specification.component.service';
import { SpecificationModel } from './specification.model';
import { MatDialog } from '@angular/material';
import { Logger } from '@app/core';
import { ProcessService } from '../component-process/component-process.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter, Output } from '@root/node_modules/@angular/core';
import { ProductDetail } from '@secure/products/models/product-detail.model';
import { ProductCategory } from '@secure/products/models/product-creation.model';

const log = new Logger('SpecificationProductComponent');

@Component({
  selector: 'app-specification-product',
  templateUrl: './specification.component.html',
  styleUrls: ['./specification.component.scss']
})

export class SpecificationProductComponent implements OnChanges, OnInit {
  @Input() category: ProductCategory;
  @Output() specificationsStepCompleted: EventEmitter<any> = new EventEmitter();
  @Output() specificationFormEmit: EventEmitter<any> = new EventEmitter();
  /** Inicialización de variables */
  chargeList = false;
  specificationsGroups: SpecificationModel[] = [];
  specificationListToAdd: any[] = [];
  public arrayPosition = [];
  ShowSpecTitle = false;
  specificationModel = new SpecificationModel(null, null, null);
  specsForm: FormControl;
  isLoad = false;
  idCategory: number;
  dataSpecification: any;
  isShow = false;
  count = 0;
  @Input() productDetails: ProductDetail;
  specificationForm: FormGroup;

  constructor(
    private specificationService: SpecificationService,
    public dialog: MatDialog,
    public processService: ProcessService,
    private languageService: TranslateService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category'].currentValue && this.category.Category) {
      this.processService.getSpecsByCategories(`${this.category.Category}`);
      this.listSpecification();
    }
  }

  ngOnInit() {
    if (this.productDetails) {

    }
  }


  listSpecification() {
    this.specsForm = new FormControl();
    if (this.processService.specsByCategory) {
      this.processService.specsByCategory.subscribe(result => {
        this.isLoad = false;
        if (result && result.data) {
          this.dataSpecification = result.data;
          this.specificationsGroups = this.specificationModel.changeJsonToSpecificationModel(result.data);
          this.createForm();
          this.specificationFormEmit.emit(this.specificationForm);
          this.setSpecification(result.data);
          const views = this.processService.getViews();
          views.showSpec = false;
          const dataClear = {
            Features: []
          };
          this.processService.validaData(dataClear);
          if (this.count >= 2) {
            this.isShow = false;
          }
          this.count = this.count + 1;
        } else {
          this.specificationsGroups = [];
          const views = this.processService.getViews();
          views.showSpec = false;
        }
        this.chargeList = true;
      });
    }
  }


  setSpecification(data: any) {
    if (this.idCategory) {
      if (data && data.length > 0) {
        let count = 0;
        this.isShow = true;
        data.forEach(element => {
          let specf = element.categories.replace(/'/g, '"');
          specf = JSON.parse(specf).find(x => x === this.idCategory.toString());
          if (specf) {
            this.specificationsGroups[count].Show = true;
          }
          count++;
        });
      }

    }
  }

  public validateObligatoryGroup(group: any): boolean {
    let hasSon = false;
    group.Sons.forEach(element => {
      if (element.Required && !element.Value) {
        hasSon = true;
      }
    });
    return hasSon;
  }

  public validForm(form: any): void {
    const views = this.processService.getViews();
    views.showSpec = !form;
    this.processService.setViews(views);
    if (this.productDetails && this.productDetails.features && this.productDetails.features.length === 0) {
      this.isShow = false;
    }
  }

  /**
   * Obtener todas las especificaciones del servicio de especificaciones.
   *
   * @memberof SpecificationProductComponent
   */
  public getAllSpecifications(): void {
    this.specificationService.getSpecifications().subscribe(data => {
      if (data.status === 200) {
        this.specificationsGroups = this.specificationModel.changeJsonToSpecificationModel(data.body.data);
      }
      this.chargeList = true;
    }, error => {
      this.chargeList = false;
      log.error(this.languageService.instant('secure.products.create_product_unit.specifications.error_has_occured_uploading'));
    });
  }

  createForm() {
    const allSons = Object.values(this.specificationsGroups)
      .map(obj => obj.Sons)
      .reduce((acc, sonsArray) => acc.concat(sonsArray), []);
    const formGroupConfig = {};
    Object.keys(allSons).forEach(key => {
      const controlConfig = allSons[key];
      const validators = [];
      if (controlConfig.Required) {
        validators.push(Validators.required);
      }
      let controlValue: any;
      if (this.productDetails) {
        const feature = this.productDetails.features.find(spec => spec.key === allSons[key].Label);
        if (feature) {
          controlValue = feature.value;
        }
      } else {
        controlValue = controlConfig.value;
      }
      formGroupConfig[`specs${controlConfig.Id}`] = [controlValue, validators];
    });
    this.specificationForm = this.formBuilder.group(formGroupConfig);
    this.specificationForm.valueChanges.subscribe(() => {
      this.specificationFormEmit.emit(this.specificationForm);
    })
  }

  /**
   * Verifica si debe agregar una especificacion o se modifico una ya existente.
   *
   * @param {SpecificationModel} model
   * @param {number} indexParent
   * @param {number} indexSon
   * @memberof SpecificationProductComponent
   */
  public specificationChange(model: SpecificationModel, indexParent: number, indexSon: number): void {
    const cont = this.verifyExist(model, indexParent, indexSon);
    if (cont === null) {
      this.specificationListToAdd.push({
        Name: model.Label,
        Key: model.Name,
        Value: this.getControlByIndex(indexSon).value,
        ExistId: indexParent + '-' + indexSon
      });
    } else {
      this.specificationListToAdd[cont].Value = this.getControlByIndex(indexSon).value;
    }
    this.validFeatureData();
  }

  getControlByIndex(index: number) {
    const controls = Object.keys(this.specificationForm.controls);
    return this.specificationForm.get(controls[index]);
  }

  checkForm() {
    this.specificationForm.markAllAsTouched();
    this.markAllAsDirty(this.specificationForm);
  }

  markAllAsDirty(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsDirty();
    });
  }

  public validFeatureData(): void {
    const list = this.specificationListToAdd.filter(spec => spec.Value);
    this.specificationsStepCompleted.emit({
      specificationsData: list,
      validStep: this.specsForm.valid,
    });
  }

  public showError(id: number): string {
    try {
      if (this.specificationForm.get(`specs${id}`).errors) {
        const errors = Object.keys(this.specificationForm.get(`specs${id}`).errors);
        switch (errors[0]) {
          case 'required':
            return this.languageService.instant('secure.products.create_product_unit.specifications.field_mandatory');
            break;
          case 'pattern':
            return this.languageService.instant('secure.products.create_product_unit.specifications.500_characters');
            break;
          default:
            return this.languageService.instant('secure.products.create_product_unit.specifications.error_field');
        }
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  /**
   * Verifica si una especificacion ya posee valor o debe crearse, usa una llave primaria creada por la
   * suma del indice del grupo de especificacion y la posicion de esta, dentro del grupo.
   *
   * @param {SpecificationModel} model
   * @param {number} indexParent
   * @param {number} indexSon
   * @returns {number}
   * @memberof SpecificationProductComponent
   */
  public verifyExist(model: SpecificationModel, indexParent: number, indexSon: number): number {
    let exist = null;
    const idCompare = indexParent + '-' + indexSon;
    let cont = 0;
    this.specificationListToAdd.forEach(data => {
      if (data.Name === model.Name && data.ExistId === idCompare) {
        exist = cont;
      }
      cont++;
    });
    return exist;
  }

  /**
   * Cuando remueve una especificacion de la lista ya agregadas.
   *
   * @param {number} index
   * @memberof SpecificationProductComponent
   */
  public removeSpecification(index: number): void {
    this.specificationListToAdd.splice(index, 1);
    let cont = false;
    for (let i = 0; i < this.specificationListToAdd.length; i++) {
      if (this.specificationListToAdd[i].Show) {
        cont = true;
      }
    }
    this.validFeatureData();
    this.ShowSpecTitle = cont;
  }

  /**
   * funcion para setear las especificaciones
   *
   * @param {*} form
   * @param {*} inputSpecifications
   * @param {number} indexParent
   * @param {number} indexSon
   * @memberof SpecificationProductComponent
   */
  setValueSpefici(form: any, inputSpecifications: SpecificationModel, indexParent: number, indexSon: number) {
    if (inputSpecifications && inputSpecifications.Label) {
      if (this.productDetails && this.productDetails.features.length > 0) {
        this.isShow = true;
        const valueArray = this.productDetails.features.find(x => x.key === inputSpecifications.Label);
        let value = '';
        if (valueArray !== undefined && valueArray !== null) {
          if (valueArray.value === null || valueArray.value === undefined) {
            value = '';
          } else {
            value = valueArray.value;
            this.specificationChange(inputSpecifications, indexSon, indexParent);
          }
          if (form && form.form) {
            if (form.form.controls['specs' + indexParent] && !form.controls['specs' + indexParent].value) {
              const isExist = this.arrayPosition.includes(indexParent);
              if (isExist === false) {
                this.arrayPosition.push(indexParent);
                setTimeout(res => {
                  form.controls['specs' + indexParent].setValue(value);
                  this.isShow = false;
                }, 45000);
              }
            } else {
              this.isShow = false;
            }
          } else {
            this.isShow = false;
          }
        } else {
          this.isShow = false;
        }
      } else {
        this.isShow = false;
      }
    }
  }
}
