<mat-card class="content-profile">

  <mat-nav-list class="light-content">
    <mat-toolbar color="primary">
      <button id="closeMenuApplication" color="accent" [matTooltip]="'actions.close_menu' | translate"
        matTooltipPosition="below" mat-icon-button mat-button (click)="toggleMenu()">
        <mat-icon>close</mat-icon>
      </button>

      <span class="spacer-content"></span>

      <div (click)="toggleMenu()" [routerLink]="['/' + routes?.secureSeller]" class="logo-application"></div>

    </mat-toolbar>

    <div class="sidebar-text-align">
      <app-select-language></app-select-language>
      <div class="sidebar-label-language">{{'select.language' | translate}}</div>
    </div>


    <!-- {{module.Menus}} -->
    <div class="content-menu-list" *ngIf="user">
      <div *ngFor="let module of modules">
        <mat-expansion-panel [id]="'ver-'+(('module.' + getPersonalityName(module.NameModule)) | translate)" class="expansion-menu-list" expanded="false" *ngIf="showModule(module) && module.NameModule !== 'Escuela Exito'">
          <mat-expansion-panel-header >
            <mat-panel-title>
              {{('module.' + getPersonalityName(module.NameModule)) | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let menu of module.Menus">
            <mat-list-item [id]="'ver-'+(('menu.' + menu.NameMenu) | translate)" (click)="toggleMenu()" [routerLink]="['/' + menu.UrlRedirect]" *ngIf="showMenu(menu)">
              <h6 matLine>{{('menu.' + menu.NameMenu) | translate}}</h6>
              <app-notification-circle *ngIf="menu.NameMenu === 'Solicitudes pendientes'" [value]="pending"
                id="circle-notification-toolbar"></app-notification-circle>
              <app-notification-circle *ngIf="menu.NameMenu === 'Listado de cancelaciones'" [value]="devolution"
                id="circle-notification-toolbar"></app-notification-circle>
            </mat-list-item>
            <a mat-list-item  [id]="'ver-'+(('module.' + getPersonalityName(module.NameModule)) | translate)" href="{{menu.UrlRedirect}}" [id]="('menu.' + menu.NameMenu) | translate"
              *ngIf="showMenu(menu, true)" target="_blank">
              <h3 matLine style="margin-left:50px;">{{('menu.' + menu.NameMenu) | translate}}</h3>
            </a>
          </div>
        </mat-expansion-panel>
      </div>
    </div>

    <!--
      <mat-expansion-panel class="expansion-menu-list" expanded="false">


        <mat-expansion-panel-header id="matExpansion-item-button-ordenes">
          <mat-panel-title>
            &Oacute;rdenes
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="user?.sellerProfile === 'seller' && !isProductionEnv">
          <mat-list-item id="matlist-item-button-{{item.buttonId}}" *ngFor="let item of categoryList" (click)="goToRoot(item); toggleMenu();">
            <h6 matLine [innerHTML]="item.name"></h6>
          </mat-list-item>
        </div>
        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOrderBillingOrders]" *ngIf="user?.sellerProfile === 'administrator'">
          <h6 matLine>Factura electrónica</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOrderInPendingDevolution]"
          *ngIf="user?.sellerProfile === 'seller' && !isProductionEnv">
          <h6 matLine>Solicitudes pendientes</h6>
        </mat-list-item>
        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOrderInValidation]" *ngIf="user?.sellerProfile === 'seller' && !isProductionEnv">
          <h6 matLine>En validación</h6>
        </mat-list-item>
        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOrderInDevolution]" *ngIf="user?.sellerProfile === 'seller' && !isProductionEnv">
          <h6 matLine>En devolución</h6>
        </mat-list-item>
        <mat-list-item id="matlist-item-button-cargar-guias" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOrderLoadGuide]"
          *ngIf="user?.sellerProfile === 'seller'">
          <h6 matLine>Cargar guías</h6>
        </mat-list-item>
      </mat-expansion-panel>
    </div>

    <div class="content-menu-list">

      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-ofertas">
          <mat-panel-title class="divider">
            Ofertas
          </mat-panel-title>

        </mat-expansion-panel-header>
        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOfersList]" *ngIf="user?.sellerProfile === 'seller'">
          <h6 matLine>Listado de Ofertas</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOferBulkLoad]" *ngIf="user?.sellerProfile === 'seller'">
          <h6 matLine>Carga Masiva</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOferHistoricalBulkLoad]"
          *ngIf="user?.sellerProfile === 'seller'">
          <h6 matLine>Histórico de Carga de Ofertas</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOferTreeCategory]" *ngIf="user?.sellerProfile === 'administrator'">
          <h6 matLine>Arbol de categorías</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes.sellerCenterIntOfferQuoting]" *ngIf="user?.sellerProfile === 'administrator'">
          <h6 matLine>Cotizador</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntOferHistoricalBulkLoadAdmin]"
          *ngIf="user?.sellerProfile === 'administrator'">
          <h6 matLine>Histórico de Carga de Ofertas</h6>
        </mat-list-item>
      </mat-expansion-panel>
    </div>

    <div class="content-menu-list">
      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-products">
          <mat-panel-title>
            Productos
          </mat-panel-title>

        </mat-expansion-panel-header>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntProductBulkLoad]" *ngIf="user?.sellerProfile === 'administrator'">
          <h6 matLine>Carga masiva</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterProductModerationBulkLoad]"
          *ngIf="user?.sellerProfile === 'administrator'">
          <h6 matLine>Moderación</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntCreateUnutaryProduct]" *ngIf="user?.sellerProfile === 'seller'">
          <h6 matLine>Creación Unitaria</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes.sellerCenterIntListProducts]">
          <h6 matLine>Listado de productos</h6>
        </mat-list-item>

      </mat-expansion-panel>
    </div>

    <div class="content-menu-list" *ngIf="user?.sellerProfile === 'administrator'">
      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-seller">
          <mat-panel-title>
            Vendedores
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list-item id="matlist-item-button-seller-register" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntSellerRegister]">
          <h6 matLine>Registrar nuevo</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntSellerManage]">
          <h6 matLine>Administrar</h6>
        </mat-list-item>

        <mat-list-item id="matlist-item-button-seller-register" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntSellerAgreements]">
          <h6 matLine>Acuerdos</h6>
        </mat-list-item>

        <mat-list-item id="matlist-item-button-seller-register" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntSellerList]">
          <h6 matLine>Listado de vendedores</h6>
        </mat-list-item>

      </mat-expansion-panel>
    </div>

    <div class="content-menu-list" *ngIf="user?.sellerProfile === 'seller'">
      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-facturacion">
          <mat-panel-title>
            Facturación
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list-item id="matlist-item-button-pagos" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntBillingPayments]">
          <h6 matLine>Pagos</h6>
        </mat-list-item>

      </mat-expansion-panel>
    </div>

    <div class="content-menu-list" *ngIf="user?.sellerProfile === 'administrator'">
      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-products">
          <mat-panel-title>
            Parametrización
          </mat-panel-title>

        </mat-expansion-panel-header>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntParamSpecs]">
          <h6 matLine>Especificaciones</h6>
        </mat-list-item>

        <mat-list-item (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntParamBrand]">
          <h6 matLine>Marcas</h6>
        </mat-list-item>


      </mat-expansion-panel>
    </div>

    <div class="content-menu-list" *ngIf="user?.sellerProfile === 'administrator'">
      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-docs">
          <mat-panel-title>
            Documentaci&oacute;n
          </mat-panel-title>
        </mat-expansion-panel-header>

        <a mat-list-item id="matlist-item-button-seller-documentation" href="http://sellercenter.api.exito.com.s3-website-us-east-1.amazonaws.com/"
          target="_blank">
          <h3 matLine style="margin-left:50px;">API</h3>
        </a>
        <a mat-list-item id="matlist-item-button-seller-documentation-front" href="http://sellercenter.frontdoc.exito.com.co.s3-website-us-east-1.amazonaws.com/"
          target="_blank">
          <h3 matLine style="margin-left:50px;">App Angular</h3>
        </a>

      </mat-expansion-panel>
    </div>
  -->

    <!--<mat-list-item id="matlist-item-button-soporte" (click)="toggleMenu();shellComponent.openDialogSupport()">
      <h6 matLine>Soporte Marketplace</h6>
    </mat-list-item> -->
    <mat-list-item id="matlist-item-button-perfil-usuario" (click)="toggleMenu()"
      [routerLink]="['/' + routes?.sellerCenterMyProfile]">
      <mat-icon matListIcon class="mat-18">person</mat-icon>
      <h6 matLine class="sidebar-margin-left">{{'core.shell.header.profile' | translate}}</h6>
    </mat-list-item>

    <mat-list-item id="matlist-item-button-cerrar-sesion" [routerLink]="['/' + routes?.sellerCenterLogout]"
      (click)="toggleMenu()">
      <mat-icon matListIcon class="mat-18">input</mat-icon>
      <h6 matLine class="sidebar-margin-left">{{'core.shell.header.logout' | translate}}</h6>
    </mat-list-item>

    <!--
    <div class="content-menu-list">
      <mat-expansion-panel class="expansion-menu-list" expanded="false">
        <mat-expansion-panel-header id="matExpansion-item-button-envios-exito">
          <mat-panel-title>
            Envios Éxito
          </mat-panel-title>

        </mat-expansion-panel-header>

        <mat-list-item id="matlist-item-button-historico" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntShipmentsExitoHist]">
          <h6 matLine>Historico</h6>
        </mat-list-item>

        <mat-list-item id="matlist-item-button-despacho" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntShipmentsExitoOffice]">
          <h6 matLine>Despacho</h6>
        </mat-list-item>

        <mat-list-item id="matlist-item-button-pendientes" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntShipmentsExitoPending]">
          <h6 matLine>Pendientes</h6>
        </mat-list-item>

        <mat-list-item id="matlist-item-button-reportes" (click)="toggleMenu()" [routerLink]="['/' + routes?.sellerCenterIntShipmentsExitoReports]">
          <h6 matLine>Reportes</h6>
        </mat-list-item>
      </mat-expansion-panel>
    </div> -->

  </mat-nav-list>
</mat-card>
