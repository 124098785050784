<div class="light-content">
  <mat-toolbar id="principal-toolbar" color="primary" class="principal-toolbar">
    <button color="accent" id="menuElement" [matTooltip]="'menu.Menu' |
      translate" matTooltipPosition="below" mat-icon-button mat-button
      (click)="toggleMenu()">
      <mat-icon class="mat-24">menu</mat-icon>
      <app-notification-circle [value]="unreadCase + sumadevolution"
        id="circle-notification-header"></app-notification-circle>
    </button>
    <div (click)="goToHome()" fxShow.xs="false" class="logo-application"></div>
    <p fxShow.xs="false" fxShow.sm="false" fxShow.md="false" fxShow.lg="true" fxShow.xl="true"
      fxShow="true" class="name-user margin-left-20">{{'core.shell.header.welcome'
      | translate}}:
      <b>{{user?.sellerName}}</b>
      <span class="sc-header-name-seller">{{'shared.id' | translate}}:
        {{user?.sellerId}}</span>
    </p>
    <span class="spacer-content"></span>

    <div fxFlex.xs="100%" fxFlex.sm="24" fxFlex.md="16" fxFlex.lg="12"
      fxFlex.xl="12" *ngIf="canView">
      <button class="sc-button-toolbar btn-school-exito" [routerLink]="['/' +
        routes?.sellerCenterIntSchoolExito]"
        id="profile-button" mat-icon-button mat-button color="accent">
        <mat-icon class="mat-24 btn-icon-color">school</mat-icon>
        <span class="btn-school-text">
          {{'school.exito' | translate}}
        </span>
      </button>
    </div>

    <div class="select-hide">
      <div><app-select-language></app-select-language></div>
      <div class="sidebar-label-language">{{'select.language' | translate}}</div>
    </div>



    <div fxLayoutGap="10px">
      <button *ngIf="user?.sellerProfile === 'seller'" class="sc-button-toolbar"
        (click)="openDialogSupport()" fxShow.xs="false" fxShow.sm="false"
        fxShow.lg="true" fxShow.xl="true" fxShow="true" id="support-link-button"
        mat-button color="accent">
        <mat-icon class="mat-24">help</mat-icon>
        {{'core.shell.header.support' | translate}}
      </button>
      <button class="sc-button-toolbar"
        [matTooltip]="'core.shell.header.profile' | translate"
        matTooltipPosition="below" [routerLink]="['/' +
        routes?.sellerCenterMyProfile]"
        id="profile-button" mat-icon-button mat-button color="accent">
        <mat-icon class="mat-24">person</mat-icon>
      </button>
      <button class="sc-button-toolbar" [matTooltip]="'core.shell.header.logout'
        | translate" matTooltipPosition="below" [routerLink]="['/' +
        routes?.sellerCenterLogout]"
        id="logout-button" mat-icon-button mat-button color="accent">
        <mat-icon class="mat-24">exit_to_app</mat-icon>
      </button>
    </div>

  </mat-toolbar>

</div>
<div style="padding-top:64px;"></div>
