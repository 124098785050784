<div class="container" fxLayout="row wrap" fxLayoutAlign="center start">

  <div fxFlex="100" class="light-content">

    <mat-toolbar class="toolbar-orders-options-table" color="primary">
      <mat-toolbar-row class="toolbar-options">

        <div class="normal-theme toolbar-options-table">
          <div fxFlex.gt-lg="60" fxFlex.gt-xs="auto">
            <h3 fxShow.xs="false" fxShow.sm="true" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
              class="sc-title title-toolbar"><b>{{'secure.products.bulk_upload.title_module' | translate}}</b> |
              {{'secure.products.bulk_upload.title_bar' | translate}}</h3>
          </div>

          <!-- Paginación de la tabla -->
          <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true" fxFlex="60"
            fXLayout="row wrap" fxLayoutAlign="center center">

            <!-- Se esconde formato de cargas, ticket 725 -->
            <div *ngIf="false">
              <!-- Input para subir archivo Excel -->
              <a download class="sc-default-button" id="download-file-button"
                (click)="downloadFormatMassiveOfferLoadinternacional()" mat-button>
                <mat-icon class="mat-18">file_download</mat-icon>
                {{'secure.products.bulk_upload.download_format' | translate}}
              </a>

              <a download class="sc-default-button" id="download-file-button" (click)="downloadFormatMassiveOfferLoad()"
                mat-button>
                <mat-icon class="mat-18">file_download</mat-icon>
                {{'secure.products.bulk_upload.download_format' | translate}}
              </a>
            </div>

            <!-- Input para subir archivo Excel -->
            <input placeholder="file upload" id="uploadFile" hidden name="fileUploadOption" type="file"
              (change)="onFileChange($event)" multiple="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              #fileUploadOption>
            <button color="primary" class="sc-default-button" id="download-file-button" (click)="openModalVtexTree()"
              [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin" mat-raised-button>
              <mat-icon class="mat-18 text_white">file_download</mat-icon>
              {{'secure.products.bulk_upload.download_template' | translate}}
            </button>
            <button color="primary" class="sc-default-button" id="load-file-button" (click)="fileUploadOption.click()"
              [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin" mat-button>
              <mat-icon class="mat-18">file_upload</mat-icon>
              {{'secure.products.bulk_upload.select_file_import' | translate}}
            </button>

          </div>

          <div fxShow.xs="true" fxShow.sm="true" fxShow.md="false" fxShow.lg="false" fxShow.xl="false"
            class="center-product-list" fxFlex="130px">
            <button id="dropdown-options-load-guide" class="button-toolbar" mat-button
              [matMenuTriggerFor]="toolbarOptions">
              <span>{{'secure.products.bulk_upload.options' | translate}}</span>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu dense #toolbarOptions="matMenu">


              <button id="upload-file-guide-button" (click)="fileUploadOption.click()" mat-menu-item>
                <span>{{'secure.products.bulk_upload.upload_file' | translate}}</span>
              </button>
              <button id="download-file-button" (click)="openModalVtexTree()"
                [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin" mat-menu-item>
                <span>{{'secure.products.bulk_upload.download_template' | translate}}</span>
              </button>
              <!-- Se esconde formato de cargas, ticket 725 -->
              <div *ngIf="false">
                <a download id="download-file-guide-button" href="assets/format_guide/Format_Guide.xlsx" mat-menu-item>
                  {{'secure.products.bulk_upload.download_format' | translate}}</a>
                <button *ngIf="fileName != ''" (click)="resetVariableUploadFile()" id="download-order-button"
                  mat-menu-item>
                  <span>{{'secure.products.bulk_upload.clear_current_data' | translate}}</span>
                </button>

                <button id="send-guide-button" (click)="sendJsonInformation()"
                  [disabled]="countErrors != 0 || countRowUpload == 0" color="accent"
                  mat-menu-item>{{'secure.products.bulk_upload.send_data' | translate}}
                </button>
              </div>
            </mat-menu>
          </div>
          <!-- Paginación de la tabla -->

        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>


  <div *ngIf="!orderListLength && countErrors > 0" class="content-expansion-orders" fxFlex.xs="93%" fxFlex.sm="95%"
    fxFlex.md="30%" fxFlex.lg="30" fxFlex.xl="40">

    <!-- Componente para visualizar la lista de errores -->
    <app-table-errors-product [fileName]="fileName" [countRowUpload]="countRowUpload" [countErrors]="countErrors"
      [listLog]="listLog"></app-table-errors-product>
  </div>

  <div *ngIf="!orderListLength && countErrors > 0" fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="60" fxFlex.lg="60"
    fxFlex.xl="50" class="content-table-orders">
    <!-- Componente para visualizar la lista de resultados obtenidos -->
    <app-table-load-product  [countErrors]="countErrors" [profielType]= "profileTypeLoad" [countRowUpload]="countRowUpload"></app-table-load-product>

  </div>

  <div fxFlex.xs="93%" fxFlex.sm="95" fxFlex.md="70" fxFlex.lg="60" fxFlex.xl="60" class="content-table-orders">
    <div *ngIf="showCharge === false">
      <aside class="flexbox align-center justify-center orders-no-data"
        *ngIf="countRowUpload == 0 && dataAvaliableLoads">
        <div class="flexbox align-center justify-center">
          <mat-icon>get_app</mat-icon>
          <p>
            {{'secure.products.bulk_upload.allowed_uploads_day' | translate}}: <strong>
              {{dataAvaliableLoads.maximumAmountAvailableLoads}} </strong>
          </p>
        </div>
        <div class="flexbox align-center justify-center">
          <mat-icon>check_circle</mat-icon>
          <p>
            {{'secure.products.bulk_upload.successful_uploads_today' | translate}}:
            <strong>{{dataAvaliableLoads.amountSuccessfulLoads}}</strong>
          </p>
        </div>
        <div class="flexbox align-center justify-center">
          <mat-icon>priority_hight</mat-icon>
          <p>
            {{'secure.products.bulk_upload.available_uploads' | translate}}: <strong>
              {{dataAvaliableLoads.amountAvailableLoads}}</strong>
          </p>
        </div>
      </aside>
    </div>

    <!-- Se cambia texto y botones, ticket 725 -->
    <div *ngIf="showCharge === false">
      <div [@detailExpand] class="orders-no-data" *ngIf="countRowUpload == 0">
        <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
          class="content-icon-no-data">
          <mat-icon id="icon-cloud-upload-file" (click)="fileUploadOption.click()"
            [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin" class="icon-not-found" color="primary">backup
          </mat-icon>
        </div>
        <div class="content-information-no-data">
          <p>{{'secure.products.bulk_upload.text_step_import' | translate}}
            <a download id="download-file-guide-button" class="link-text">
              {{'secure.products.bulk_upload.download_the_format' | translate}}
            </a> <a download id="download-file-guide-button"
              class="link-text">(Download format)</a>,
            {{'secure.products.bulk_upload.text_step_import_two' | translate}}
          </p>
          <p>
            <span class="link-text" id="upload-file-guide-button" (click)="fileUploadOption.click()"
              [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin">{{'secure.products.bulk_upload.text_select_import' | translate}}</span>
            {{'secure.products.bulk_upload.text_select_import_type' | translate}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="showCharge === true">
      <div [@detailExpand] class="orders-no-data" *ngIf="countRowUpload == 0">
        <div fxShow.xs="false" fxShow.sm="false" fxShow.md="true" fxShow.lg="true" fxShow.xl="true"
          class="content-icon-no-data">
          <mat-icon id="icon-cloud-upload-file" (click)="fileUploadOption.click()"
            [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin" class="icon-not-found" color="primary">backup
          </mat-icon>
        </div>
        <div class="content-information-no-data">
          <p>
            {{'secure.products.bulk_upload.text_import_product' | translate}}
            <!-- Para importar los productos al Seller Center deberás SIEMPRE descargar la plantilla de la categoría para la cual desees crear productos. Recuerda llenar la plantilla de la categoría correspondiente a tus productos completamente y revisando las restricciones de cada campo. En caso de que tus productos no tengan EAN, debes enviar el campo con un <b>cero (0)</b> en la plantilla. -->
          </p>
          <p>
            <span class="link-text" id="upload-file-guide-button" (click)="fileUploadOption.click()"
              [class.no-data-avaliable]="!dataAvaliableLoads && isAdmin">{{'secure.products.bulk_upload.text_select_import' | translate}}</span>
            {{'secure.products.bulk_upload.text_select_import_type' | translate}}</p>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalContent>
  <div class="container--text__sticky">
    <p>{{'secure.products.bulk_upload.select_product_category' | translate}}</p>
    <p>{{'secure.products.bulk_upload.select_category' | translate}} <span class="badge badge-blue"
        *ngIf="!!categoryName && !!categoryName.value">{{categoryName.value}}</span></p>
  </div>
  <div *ngIf="isLoad" class="loading-indicator">
    <mat-spinner [diameter]="60" mode="indeterminate"></mat-spinner>
  </div>
  <div *ngIf="!isLoad">
    <div>
      <app-category-tree [categoryList]="vtextree" [categoryComponent]="this" [canCreate]="false" [canUpdate]="false" [canDelete]="false"
        [margin]="0"></app-category-tree>
    </div>
  </div>
</ng-template>
