<div>
  <mat-toolbar class="tree-toolbar">
    <div class="normal-theme toolbar-options-table flexbox justify-start
      align-center" fxLayout.xs="column" id="widthTitileExc">
      <!-- Titulo del toolbar -->
      <p class="tree-title">
        <strong>Parametrización</strong>
        <small class="sub-title">| Excepción de comisión</small>
      </p>

      <!-- Componente para realizar la busqueda de una tienda -->
      <div class="search-container">
        <app-search-seller id="contet-searc-store" class="search-content">
        </app-search-seller>
      </div>
      <div>
        <div fxLayout="column">
          <div style="text-align: center; height: 24px;">
            <span style="font-size: 14px; vertical-align: bottom;">Carga masiva
              de comisión de PLU</span>
          </div>
          <div fxLayout="row">
            <div>
              <input placeholder="file upload" id="uploadFile" hidden
                name="fileUploadOption" type="file"
                (change)="onFileChange($event)" multiple="false"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/vnd.ms-excel"
                #fileUploadOption>
              <a
                href="{{url}}"
                target="_parent">
                <button color="primary" style="margin-right: 10px; height:
                  29px;" class="sc-default-button" id="download-file-button"
                  mat-button>
                  <mat-icon class="mat-18 text_white">file_download</mat-icon>
                  Descargar formato
                </button>
              </a>
              <button color="primary" style="height: 29px;"
                class="sc-default-button" id="load-file-button"
                (click)="fileUploadOption.click()"
                mat-button>
                <mat-icon class="mat-18">file_upload</mat-icon>
                {{'secure.products.bulk_upload.select_file_import' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </mat-toolbar>
</div>



<div *ngIf="activeException" class="marginComission">
  <app-exception-brand  [currentStoreSelect]="currentStoreSelect.IdSeller"></app-exception-brand>
</div>


<div class="orders-no-data" *ngIf="!activeException">
  <mat-icon class="icon-not-found" color="primary">info</mat-icon>
  <div>
    <p>Ingrese el nombre de la tienda en la barra de búsqueda para ver las
      excepciones.</p>
  </div>
</div>
