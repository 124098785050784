<div class="height-full">
  <mat-toolbar class="tree-toolbar">
    <div class="normal-theme toolbar-options-table">
     <!--  Titulo del toolbar -->
      <p class="tree-title">{{'secure.products.create_product_unit.create_unutary_product.products' | translate}}
        <small class="sub-title">| {{'secure.products.create_product_unit.create_unutary_product.unitary_creation' | translate}}</small>
      </p>
    </div>
  </mat-toolbar>
  <div class="process-container">
      <app-component-process [ean]="ean" [reference] ="reference" [pendingProduct]="pendingProduct"></app-component-process>
  </div>
</div>

