export class VtexTree {
  static VTEX_TREE: any[] = [
    {
      productType: 'Technology',
      Name: 'Mercado',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Pollo, Carne y Pescado',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Pollo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Carne de res',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Carne de cerdo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pescados y Mariscos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pavo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Gallina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Codorniz',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pato',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Bufalo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Ternera',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cordero',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Conejo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Embutidos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Carnes frías',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Delicatessen',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Quesos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Carnes Especiales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tablas y picadas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Frutos y semillas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Encurtidos, Conservas, Pates y Dulces',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Frutas y Verduras',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Frutas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Verduras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Hierbas y Aromaticas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Otras Hortalizas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Flores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Panaderia y Reposteria',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Pan empacado',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Panes blancos y tostados',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Panaderia dulce',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Postres y tortas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Waffles y muffins',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Ingredientes para Reposteria',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Reposteria No Refrigerada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Reposteria Refrigerada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Panes Saludables',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Panaderia de Queso',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pan Frances',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Despensa',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cereales y Granolas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Granos, Arroz y Sal',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aceites y Vinagres',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Azúcar, panela y endulzante',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Harinas y mezclas para preparar',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pastas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Salsas, Especias y Condimentos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Enlatados y conservas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tortillas y tacos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sopas y Cremas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Soya y avena',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Bebidas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Alimentos Naturistas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aromanicas y Té',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cremas no lacteas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Postres para preparar',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Compotas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Café y Chocolates',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Para untar',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Antipastos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Guacamole',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Dips, pates y mousses',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Queso cremas y sueros',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Mantequilla y margarina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Dulces',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Mermeladas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Gelatinas en polvo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Alimento Lacteo en Polvo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Vinos y licores',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Vinos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Whisky',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cognac',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Ginebra',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Brandy',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Vodka',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tequila',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cervezas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aguardiente',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Ron',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aperitivos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cocteles y bases para coctel',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Lacteos, huevos y Refrigerados',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Leche',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Leches Saborizadas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Leches en Polvo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Huevos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Formulas Materno Infantil',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Yogurt y bebidas Lácteas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Quesos y Quesitos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Postres Refrigerados',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Dulces tipicos y a base de leche',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cremas a base de leche',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Arepas y torillas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Congelados',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Comidas y Masas Refrigeradas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Hamburguesas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Comida Preparada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Helados tortas y Postres',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Hielo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Fruta y Pulpa Congelada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Papas, yucas y verduras congeladas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pasabocas y Panaderia Congelados',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Veganos congelados',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Snacks',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Pasabocas para preparar',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Frutos Secos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Papas Fritas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Rosquitas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Besitos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pasabocas de Maiz',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Platanos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tocinetas y chicharron',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Crispetas Listas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tortillas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Confiteria y dulces',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Chocolates',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Dulces típicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Galletas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aseo del hogar',
      TipodeObjeto: 'Nivel 2'
    },
    // {
    //   productType: 'Technology',
    //   Name: 'Ambientadores',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Cuidado de la cocina',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Cuidado de la ropa',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Cuidado del baño',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Cuidado del hogar',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Implementos para la limpieza',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Desechables, carbon, palillos',
    //   TipodeObjeto: 'Nivel 3'
    // },
    {
      productType: 'Technology',
      Name: 'Mascotas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Alimentos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Mascotas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Belleza e Higiene',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Salud y Belleza',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado Capilar',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Shampo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Acondicionador',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cremas para peinar',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tratamientos Capilares',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Fijadores y modeladores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Coloración',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios para el Cabello',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado corporal',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cremas de manos y cuerpo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Jabones de Tocador',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de baño',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado de manos y pies',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Desodorantes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cremas antipanalitis y vaselin',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Talcos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Copitos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aceites',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aseo personal kids',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Proteccion solar',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Spa',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Repelentes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Estropajos y Esponjas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cosmeticos y maquillaje',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Maquillaje',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Esmaltes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Base',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Polvo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Rubor',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pestañina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sombras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Delineador',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Lápices labios',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Labial',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Lápices ojos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aplicadores y Brochas de maquillaje',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Estucheria de Maquillaje',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios cosméticos',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Depiladores',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Encrespador',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Esponjas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tajalapiz',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Espejos',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Estucheria accesorios',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Cosmetiqueras',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Masajeadores',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Tratamientos',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tonico',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Limpiadora',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Humectante',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Nutritiva',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Desmanchadoras',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Anti acne',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Mascarillas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Protectores solares',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tratamiento capilar',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tratamiento corporal',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Estucheria tratamiento',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Dermo cosmetica',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Fragancias y Perfumes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado Oral',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cremas Dentales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Enjuagues Bucales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cepillos Dentales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sedas Dentales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Higiene Intima',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Toallas higienicas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tampones',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Protectores diarios',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado intimo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Proteccion incontinencia',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Afeitada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Preparacion afeitada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Depilacion',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Preparacion para la Depilacion',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pañales y Pañitos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Panitos humedos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pañales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Baberos desechables',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cambiadores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pañuelos desechables',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Droguería',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Heridas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Dolor',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Piel',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Estomago',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Gripa-tos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Salud sexual',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Vitaminas y suplementos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Proteinas y suplementos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Dispositivos medicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tecnologia',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Drones',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'GPS',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Televisores',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'HD',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'FHD',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: '4K UHD',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'OLED',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'QLED',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'SUHD',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'LED',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Monitores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Soportes para TV y accesorios',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Celulares',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Smartphones',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Celulares Basicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Smartwatch',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios para celular',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Consolas y Videojuegos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Consolas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Consolas portatiles',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Video Juegos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Perifericos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios para videojuegos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Computadores y accesorios',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Computadores Portátiles',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Computadores 2 en 1',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Computadores all in one',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Computadores de escritorio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Tablets',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Impresoras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de computador',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'UPS',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Audio y vídeo',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Camaras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Minicomponentes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Parlantes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Audifonos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Barras de Sonido',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Equipos de sonido',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Teatros en casa',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'DVD y Blu-Ray',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Reproductores y Accesorios',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Portables Audio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Equipos de audio para automovil',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de Audio y video',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Teléfonos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Moda y accesorios',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Relojes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Gafas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Clothing',
      Name: 'Moda Mujer',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Clothing',
      Name: 'Exterior Femenino',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Calzado',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Interior femenino',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Ropa deportiva para mujer',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Pijamas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Moda Hombre',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Clothing',
      Name: 'Hombre Exterior',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Calzado',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Ropa deportiva Hombre',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Ropa interior masculina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Pijamas Hombre',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Moda Niña',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Clothing',
      Name: 'Exterior Niña',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Interior Niña',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Pijamas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Calzado',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Ropa Deportiva Niña',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Moda Niño',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Clothing',
      Name: 'Exterior Niño',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Interior Niño',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Pijamas Niño',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Calzado',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Ropa Deportiva Niño',
      TipodeObjeto: 'Nivel 3'
    },
    // {
    //   productType: 'Technology',
    //   Name: 'Maletas y Bolsos',
    //   TipodeObjeto: 'Nivel 2'
    // },
    {
      productType: 'Technology',
      Name: 'Accesorios de Moda',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Relojes',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Gafas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Collar',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Aretas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Anillos',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Manillas y Pulseras',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Dijes',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Cadenas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Cinturones',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Correas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Bufandas y pashminas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Pañoletas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Pañuelos y Pochette',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Guantes',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Corbatas y Corbatines',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Mancornas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Gorras',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Sombrero',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Boinas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tirantas Adulto',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tirantas Niño',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Sombrillas y paraguas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Llaveros',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Accesorios para el Cabello',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Clothing',
      Name: 'Moda Bebes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Clothing',
      Name: 'Bebe Unisex',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Bebe Niña',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Clothing',
      Name: 'Bebe Niño',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Marroquineria',
      TipodeObjeto: 'Nivel 2'
    },
    // {
    //   productType: 'Technology',
    //   Name: 'Maletas',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Maletines',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Billeteras',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Accesorios Marroquineria',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Morrales',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Loncheras',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Riñoneras',
    //   TipodeObjeto: 'Nivel 3'
    // },
    // {
    //   productType: 'Technology',
    //   Name: 'Bolsos',
    //   TipodeObjeto: 'Nivel 3'
    // },
    {
      productType: 'Technology',
      Name: 'Bebes',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de cuna Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Grandes Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado Alimentación Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Pequenos Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado Salud & Aseo Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado Entretencion Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado Seguridad Bebe',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Electrodómesticos',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Grandes Electrodomésticos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Refrigeracion',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Lavado y Secado',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Calefacción',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cocción',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Máquinas de coser, bordaras y fileteadoras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pequeños Electromésticos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Preparacion de alimentos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cocción eléctrica',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios preparacion de Alimentos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aires acondicionados',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Ventilacion y calefaccion',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Calentadores de Agua',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado de Pisos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado de Ropa',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuidado personal',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Hogar',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Colchones',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Sencillo',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Semidoble',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Doble',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Queen',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Semi King',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'King',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Muebles',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Oficina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Muebles de interior',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Muebles de Exterior',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Oficina y estudio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Decoración',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Figuras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Relojes Decorativos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Portaretratos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Floreros',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Velas y escencias',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Candelabros',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Canastos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Iluminacion',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Espejos Decorativos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Ropa Hogar',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Toallas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sala',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cocina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Complementos Baño',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Juegos de Cama',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Almohada',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cojines',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Protectores de cama y muebles',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Complemento De Cama',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Edredones',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Duvet',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Plumones',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cobijas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sobrecamas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Infantil',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Alfombras y tapetes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sabanas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cortinas y persianas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cocina',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Preparacion Alimentos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Utensilios de cocina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Organizadores',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Organizadores de Cocina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Multiusos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Organizadores para Sala',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Organizadores Alcoba y estudio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Organizadores de Oficina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Patio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Mesa',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Vajillas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Cubiertos',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Complementos de mesa',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Vasos y copas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Complementos de bar',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Recipientes para Servir',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Mugs',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Servicio de Mesa infantil',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Jarras',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Utensilios de mesa',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Navidad',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Arboles de Navidad',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Adornos Casa',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Pesebres',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Adornos del arbol',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Luces',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Gorros y disfraces de navidad',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Adornos árbol',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Halloween',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Accesorios de Disfraces Halloween',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Decoracion de Casa Halloween',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Disfraces Halloween',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Luces Halloween',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Vehículos',
      TipodeObjeto: 'Nivel 1'
    },
    // {
    //   productType: 'Technology',
    //   Name: 'Carros',
    //   TipodeObjeto: 'Nivel 2'
    // },
    //  {
    //    productType: 'Technology',
    //    Name: 'Taxis',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Automoviles',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Camperos',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Camiones',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tractores',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Autos Usados',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    // {
    //   productType: 'Technology',
    //   Name: 'Motos',
    //   TipodeObjeto: 'Nivel 2'
    // },
    //  {
    //    productType: 'Technology',
    //    Name: 'Dos Ruedas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Tres Ruedas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Cuatro Ruedas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Scooter',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Accesorios Motos',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Llantas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Llantas para Motos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Llantas Automovil',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Llantas Campero',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Neumático',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Rines',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Baterías',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cosmetica Vehiculo',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Brilladores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Desmanchadores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Siliconas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aseo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios De Aseo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Interior',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Exterior',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Mantenimiento',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Lubricantes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Equipo De Carretera',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Extintores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Kits Equipo Carretera',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Aspiradoras Auto',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Hidrolavadoras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Libros y papeleria',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Papeleria',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Escritura',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cuadernos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Blocks',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Papeles escolares y de oficina',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Libretas y Apuntes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de Oficina y estudio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Manualidades',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Textos Escolares',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Scrapbook',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Revistas y periodicos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Libros',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Literatura y ficción',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Negocios y profesionales',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'No ficción',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Otros',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Musica',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Ferreteria',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Complementos del Hogar',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Elementos de fijación',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Elementos De Sujeción',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Repuestos Hogar',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Encendedores',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Cerrajeria',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Escaleras',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Elementos Seguridad',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Basculas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Accesorios para el Baño',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Herramientas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Herramientas Eléctricas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Herramientas Manuales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios para vehiculos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Organizadores de ferreteria',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Porta Herramientas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pinturas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Jardineria',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Insumos de jardineria',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Herramientas de jardineria',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Accesorios Riego',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Materas',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Soportes',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Adhesivos y pegantes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Pegantes',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cintas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Iluminación',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Pilas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Eléctricos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Extensiones',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Eléctricos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Servicios',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Garantia Extendida',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Recargas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Tarjetas regalo',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Goticas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Deportes',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Ciclismo',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Bicicletas',
      TipodeObjeto: 'Nivel 3'
    },
    // {
    //   productType: 'Technology',
    //   Name: 'Accesorios',
    //   TipodeObjeto: 'Nivel 3'
    // },
    {
      productType: 'Technology',
      Name: 'Repuestos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Camping',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Carpas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Asadores',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sleeping',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Cobijas y mantas camping',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Muebles',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Colchones y colchonetas camping',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Neveras térmicas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Termos térmicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Lámparas y linternas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Enseres',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Brújulas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de Camping',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Navajas y cuchillos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pesca',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Deportes acuaticos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Inflables acuáticos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Natación',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Buceo',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Piscinas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Acondicionamiento Fisico',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Caminadoras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Trotadoras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Escaladoras',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Elipticas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Estáticas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Spinning',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Máquinas Abdominales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Máquinas Multifuncionales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Musculación',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Gimnasio',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Plataformas Vibratorias',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Complementos de Gimnasia',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Artes marciales y boxeo',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Futbol',
      TipodeObjeto: 'Nivel 2'
    },
    //  {
    //    productType: 'Technology',
    //    Name: 'Balones Fútbol',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Proteccion Fútbol',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Mallas y Porterias',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    //  {
    //    productType: 'Technology',
    //    Name: 'Balones infantiles',
    //    TipodeObjeto: 'Nivel 3'
    //  },
    {
      productType: 'Technology',
      Name: 'Patinaje',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Voleyboll',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Basketball',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Tenis',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Playa',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Montaña',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Golf',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Instrumentos de medida',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Bolos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Otros deportes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Suministros Médicos',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Equipos Médicos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Tensiometros',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Camas y Camillas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Básculas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Oximetros',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Médicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Monitores Médicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Equipos Movilidad',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Muletas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Sillas de Ruedas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Caminadores y Bastones',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Movilidad',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Equipos Ortopedicos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Fajas Ortopédicas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Correctores de postura',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Soportes Ortopedicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Ortopedicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Jugueteria',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Juegos didácticos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Muñecas y accesorios',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Muñecas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios de Muñecas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'lego y armables',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Peluches',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Juegos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Juegos Electrónicos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Juegos Educativos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Juegos Multiples',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Juegos Musicales',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pelotas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Juegos de Playa',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Juegos de Salón',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Jugueteria Bebé',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Escolar',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Montables',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Impulso',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Montables de Batería',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pedal',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Otros juguetes',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Juegos musicales',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Vehículos',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Carros',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Motos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Carros y Motos de Colección',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Pistas',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Otros vehiculos',
      TipodeObjeto: 'Nivel 3'
    },
    {
      productType: 'Technology',
      Name: 'Figuras de Acción',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Instrumentos musicales',
      TipodeObjeto: 'Nivel 1'
    },
    {
      productType: 'Technology',
      Name: 'Viento',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Cuerdas',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Percusión',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Teclados',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Amplificadores',
      TipodeObjeto: 'Nivel 2'
    },
    {
      productType: 'Technology',
      Name: 'Accesorios Instrumentos',
      TipodeObjeto: 'Nivel 2'
    }
  ];
}
