<mat-toolbar class="toolbar-orders-list" color="primary">
  <mat-toolbar-row class="toolbar-orders-list">
    <div fxLayout="row" class="width-100">
      <div class="width-20px" (click)="scrollLeft()" *ngIf="showArrowMenu">
        <mat-icon class="style-arrow-menu-header">keyboard_arrow_left</mat-icon>
      </div>
        <div class="contenedor-menu-scroll" #widgetsContent>
          <div  class="content-options-toolbar width-100">
            <div *ngFor="let module of modules">
              <button [id]="('module.' + getPersonalityName(module.NameModule))
                | translate" class="button-toolbar" mat-button
                [matMenuTriggerFor]="orders"
                *ngIf="showModule(module)">
                <mat-icon>arrow_drop_down</mat-icon>
                {{('module.' + getPersonalityName(module.NameModule)) |
                translate}}
                <app-notification-circle *ngIf="module.NameModule ===
                  'RECLAMACIONES'" [value]="unreadCase"
                  id="circle-notification-toolbar"></app-notification-circle>
                <app-notification-circle *ngIf="module.NameModule ===
                  'DEVOLUCIONES'" [value]="sumadevolution"
                  id="circle-notification-toolbar"></app-notification-circle>
              </button>

              <mat-menu #orders="matMenu">
                <div *ngFor="let menu of module.Menus">
                  <button [id]="('menu.' + menu.NameMenu) | translate"
                    mat-menu-item [routerLink]="['/' + menu.UrlRedirect]"
                    *ngIf="showMenu(menu) && menu.NameMenu !=='Creación
                    Unitaria' && user && user.sellerProfile ===
                    'administrator'">
                    <span>{{('menu.' + menu.NameMenu) | translate}}</span>
                    <app-notification-circle *ngIf="menu.NameMenu ===
                      'Solicitudes pendientes'" [value]="pending"
                      id="circle-notification-toolbar"></app-notification-circle>
                  </button>
                  <button [id]="('menu.' + menu.NameMenu) | translate"
                    mat-menu-item [routerLink]="['/' + menu.UrlRedirect]"
                    *ngIf="showMenu(menu) && user && user.sellerProfile !==
                    'administrator'">
                    <span>{{('menu.' + menu.NameMenu) | translate}}</span>
                    <app-notification-circle *ngIf="menu.NameMenu ===
                      'Solicitudes pendientes'" [value]="pending"
                      id="circle-notification-toolbar"></app-notification-circle>
                  </button>
                  <a mat-list-item class="href-toolbar-doc"
                    id="matlist-item-button-seller-documentation" [id]="('menu.'
                    + menu.NameMenu) | translate"
                    href="{{menu.UrlRedirect}}" *ngIf="showMenu(menu, true)"
                    target="_blank">
                    <h3 matLine>{{('menu.' + menu.NameMenu) | translate}}</h3>
                  </a>
                </div>
              </mat-menu>
            </div>
          </div>


        </div>
        <div class="width-60" (click)="scrollRight()" *ngIf="showArrowMenu">
        <mat-icon class="style-arrow-menu-header">keyboard_arrow_right</mat-icon>
      </div>
      </div>

    </mat-toolbar-row>
  </mat-toolbar>