import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormGroupDirective, NgForm } from '@angular/forms';
import { EanServicesService } from '../validate-ean/ean-services.service';
import { ErrorStateMatcher } from '@angular/material';
import { ProcessService } from '../component-process/component-process.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { CommonService } from '@app/shared';
import { ProductDetail } from '@secure/products/models/product-detail.model';
import { ProductEan } from '@secure/products/models/product-creation.model';

// Error when invalid control is dirty, touched, or submitted.
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-validate-ean',
  templateUrl: './validate-ean.component.html',
  styleUrls: ['./validate-ean.component.scss']
})
export class ValidateEanComponent implements OnInit {
  @Input() productDetails?: ProductDetail;
  @Output() eanStepCompleted: EventEmitter<{ eanData: ProductEan, validStep: boolean }> = new EventEmitter();
  @Output() eanForm: EventEmitter<any> = new EventEmitter();
  options: FormGroup;
  eanGroup: FormGroup;
  public validateEanExist;
  public formatEan;
  public activeButtonCreacionUnitaria: boolean;
  public asignatedEan: boolean;
  public copy = null;
  @Input() ean: string;
  @Input() reference: string;
  eanControl: FormControl;
  associateEanControl: FormControl;

  public productEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: EanServicesService,
    private process: ProcessService,
    private commonService: CommonService
  ) {
    this.getRegex();
  }

  ngOnInit(): void {
    this.initFormInvalid();
  }

  initFormInvalid() {
    this.eanGroup = this.formBuilder.group({
      eanCtrl: [null, Validators.required],
    });
    this.eanForm.emit(this.eanGroup);   
  }

  getRegex() {
    this.commonService.getAllRegex().subscribe(result => {
      if (result.status === 200) {
        const regex = JSON.parse(result.body.body).Data;
        this.formatEan = regex.find(reg => reg.Identifier === 'ean').Value;
        this.initForm();
        this.eanGroup = this.formBuilder.group({
          eanCtrl: this.eanControl,
          associateEan: this.associateEanControl
        });
        this.eanForm.emit(this.eanGroup);
      }
    });    
  }
  isProductEdition(): boolean {
    return !!this.productDetails;
  }
  initForm(): void {
    this.eanControl = new FormControl(this.productDetails && this.productDetails.ean ? this.productDetails.ean : '', [Validators.required, Validators.pattern(this.formatEan)]);
    this.associateEanControl = new FormControl(this.productDetails ? !this.productDetails.hasEan : false);
    this.eanControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe(val => {
      if (!!val && !!this.eanGroup.valid) {
        this.validateEanServices();
      }
      this.eanForm.emit(this.eanGroup);
    });
    this.validateEanExist = true;
    if (this.isProductEdition()) {
      this.eanControl.disable();
      this.sendEan();
      this.associateEanControl.disable();
    }
  }

  onAsignatedEanChanged(value: boolean) {
    this.asignatedEan = value;
    if (this.asignatedEan === true) {
      this.eanControl.clearValidators();
      this.sendEan();
      this.eanControl.setValue('');
      this.eanControl.disable();
      if (!this.eanControl.value) {
        const data = {
          AssignEan: this.asignatedEan
        };
        this.process.validaData(data);
      } else {
        this.process.unavailableEanView();
      }
    } else {
      this.eanControl.setValidators([Validators.required, Validators.pattern(this.formatEan)]);
      if (!this.eanControl.value && !value) {
        this.process.unavailableEanView();
      } else {
        this.sendEan();
      }
      this.eanControl.enable();
    }
    this.eanForm.emit(this.eanGroup);
  }

  public sendEan(): void {
    const data: ProductEan = {
      Ean: this.eanControl.value,
      HasEAN: this.isProductEdition() ? true : this.associateEanControl.value,
      AssignEan: this.isProductEdition() ? false :this.associateEanControl.value,
    };
    this.eanStepCompleted.emit({
      eanData: data,
      validStep: this.eanGroup.valid
    });
  }

  // Consumiendo servicio para validar si el EAN es valido y si existe en la base de datos
  validateEanServices() {
    this.activeButtonCreacionUnitaria = false;
    if (this.eanControl.value.match(this.formatEan)) {
      this.service.validateEan(this.eanControl.value).subscribe(res => {
        // Validar si la data es un booleano
        this.validateEanExist = (res['data']);
        if (this.validateEanExist) {
          if (this.productEdit) {
            this.activeButtonCreacionUnitaria = true;
            this.sendEan();
          } else {
            this.eanControl.setErrors({ 'validExistEanDB': this.validateEanExist });
            this.process.unavailableEanView();
          }
        }
        if (!this.validateEanExist) {
          this.activeButtonCreacionUnitaria = true;
          this.sendEan();
        }
      });
    }
  }

  nextStepEan() {
    this.eanGroup.markAllAsTouched();
    this.eanGroup.markAsDirty();
  }
}
