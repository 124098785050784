<section fxLayout="column" class="specification-dialog" fxLayoutAlign="center center">
  <div class="container-icon" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon class="material-icons">
      assignment
    </mat-icon>
  </div>
  <h1>
    {{'secure.products.create_product_unit.specifications.dialog.add_specifications' | translate}}
  </h1>
  <p class="text">
    {{'secure.products.create_product_unit.specifications.complete_name' | translate}}
  </p>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="specification-form-container">
    <form #specification="ngForm" fxLayout="column" [formGroup]="formSpecification" (ngSubmit)="closeDialog(true)"
      class="specification-form">
      <!-- Fields container -->
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
        <!-- Name input Field -->
        <mat-form-field class="specification-form-field">
          <input matInput formControlName="Name"
            [placeholder]="'secure.parametize.dialog.modal_placeholder_specification_name' | translate"
            (blur)="onBlur('Name')">
          <mat-error *ngIf="formSpecification.controls.Name.errors && formSpecification.controls.Name.errors.required">
            {{'secure.products.create_product_unit.specifications.dialog.add_specifications' | translate}}
          </mat-error>
          <mat-error *ngIf="formSpecification.controls.Name.errors && formSpecification.controls.Name.errors.maxlength">
            {{'secure.products.create_product_unit.specifications.500_characters' | translate}}
          </mat-error>
        </mat-form-field>

        <!-- Value Field -->
        <mat-form-field class="specification-form-field">
          <input matInput formControlName="Value"
            [placeholder]="'secure.products.create_product_unit.specifications.specs' | translate"
            (blur)="onBlur('Value')">
          <mat-error
            *ngIf="formSpecification.controls.Value.errors && formSpecification.controls.Value.errors.required">
            {{'secure.products.create_product_unit.specifications.field_mandatory' | translate}}200_characters
          </mat-error>
          <mat-error
            *ngIf="formSpecification.controls.Value.errors && formSpecification.controls.Value.errors.maxlength">
            {{'secure.products.create_product_unit.specifications.500_characters' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
        <div fxFlex="46" fxLayout="row" fxLayoutAlign="end center">
          <button type="button" id="btn-create-transporter" mat-stroked-button color="primary"
            (click)="closeDialog(false)">
            {{'actions.close' | translate}}
          </button>
        </div>
        <div fxFlex="46" fxLayout="row" fxLayoutAlign="start center">
          <button id="btn-create-transporter" [disabled]="!formSpecification.valid" mat-raised-button color="primary">
            {{'actions.save' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
