<!-- Web Desing -->
<div class="profile-container">
    <mat-toolbar class="toolbar">
        <!-- Titulo del toolbar -->
        <p class="title">{{'secure.parametize.profiles.profile.toolbar_title' | translate}}
                <small class="sub-title">| {{'secure.parametize.profiles.profile.toolbar_subtitle' | translate}}</small>
            </p>
    </mat-toolbar>

    <!-- LISTA -->
    <div class="profile-list-container" fxLayout="column">
        <div fxLayout="row" class="header" fxLayoutAlign="space-between center">
            <div fxLayout="column" class="title-container">
                <label class="title">
                    {{'shared.name' | translate}}
                </label>
                <span class="subtitle-item">{{'secure.parametize.profiles.profile.tbl_lbl_subtitle' | translate}}</span>
            </div>
            <button mat-raised-button *ngIf="canCreate" color="primary" (click)="openDialogProfile(null)">
                <mat-icon class="material-icons icon-profile">
                    add
                </mat-icon>
                {{'actions.add' | translate}}
            </button>
        </div>
        <div class="list" *ngFor="let profile of profileList; let i = index" fxLayout="row" fxLayoutAlign="space-between center"
            fxLayoutAlign="space-between center">
            <div class="item-list" fxLayout="row">
                <div class="account-circle">
                    <mat-icon class="material-icons icon-profile" *ngIf="profile.Type == 1" [matTooltip]="'secure.parametize.profiles.profile.seller_type' | translate">
                        people
                    </mat-icon>
                    <mat-icon class="material-icons icon-profile" *ngIf="profile.Type == 0" [matTooltip]="'secure.parametize.profiles.profile.admin_type' | translate">
                        person
                    </mat-icon>
                </div>
                <div fxLayout="column">
                    <span class="account-title" id="name-i">{{profile.Name}}</span>
                    <span class="subtitle-item">{{profile.TypeName}}</span>
                </div>
            </div>
            <button mat-icon-button [matTooltip]="'actions.edit' | translate" (click)="openDialogProfile(profile)">
                <mat-icon class="edit-profile material-icons">create</mat-icon>
            </button>
        </div>
    </div>
</div>