<div class="content-order my-content">
  <div fxFlex class="container" fxLayout="row wrap" fxLayoutAlign="center
    start">
    <div fxFlex="100" class="light-content">
      <app-toolbar-search-pagination [isClear]="isClear" [informationToForm]="informationToForm" [state]="lastState"
        [idSeller]="idSeller" #toolbarOptions (OnGetOrdersList)="getOrdersList($event)"
        (paginationListOrdens)="paginations($event)" [isFullSearch]="isFullSearch" [pageIndexChange]="pageIndexChange"
        (OnChangeSizeOrderTable)="changeSizeOrderTable($event)" [downloadPermission]="downloadPermission"
        [Typeprofile]="typeProfile" [allFilter]="allFilter" [showLoading]="false" [limitPaginate]="[50, 100, 150]"
        [lengthOrder]="length" [limitSizeList]='pageSize' [showBtn]="false" [btnFilter]="false"></app-toolbar-search-pagination>
    </div>

    <ng-template [ngIf]="dataListOrder">
      <app-list-order [data]="dataListOrder" class="width-100">
        <ng-container *itemDropDownList>
          <app-box-list *ngFor="let item of dataListOrder; let i = index" [data]="item"
            [isInternational]="isInternational" [arrayPermission]="arrayPermission" (opened)="isOpened($event)"
            (showDetail)="consultDetails($event, item)" [user]="user" [index]="i" [typeProfile]="typeProfile">
            <ng-container *appContentDropDownDetailOrderDirective>
              <div *ngIf="item.statusLoad" class="content-detail-order content-table-orders">
                <app-client-information [order]="item" [isInternational]="isInternational"></app-client-information>
                <app-products-order  [user]="user" [Typeprofile]="typeProfile" [isInternational]="isInternational"
                  [dataSource]="dataListOrder" [order]="item"></app-products-order>
              </div>
            </ng-container>
          </app-box-list>
          <div [@detailExpand] class="orders-no-data" *ngIf="orderListLength && typeProfile == 0">
            <mat-icon class="icon-not-found" color="primary">info</mat-icon>
            <p>{{'secure.orders.order_list.order_page.no_orders_found' | translate}}
            </p>
          </div>
          <div [@detailExpand] class="orders-no-data" *ngIf="orderListLength && typeProfile == 1 ">
            <mat-icon class="icon-not-found" color="primary">info</mat-icon>
            <div *ngIf="showMenssage == true && idSeller">
              <p>No se han encontrado &oacute;rdenes</p>
            </div>
            <div *ngIf="showMenssage == false">
              <p>{{'secure.orders.order_list.order_page.search_orders_insert' | translate}}</p>
            </div>
          </div>
        </ng-container>
      </app-list-order>
    </ng-template>
  </div>
</div>
