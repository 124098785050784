<div class="light-content">
  <mat-toolbar color="primary" class="toolbar-component">
    <div class="toolbar-options menu flexbox flex-wrap align-center justify-between">
      <p>{{tittleBar}}
        <small class="sub-title">| {{subtitleBar}}</small>
      </p>
      <div class="options-toolbar flexbox align-center justify-end">
      </div>
    </div>
  </mat-toolbar>
</div>